import { PreOrderProduct } from "../../Types/productType";
import { makeStyles } from "@material-ui/core";

type Props = {
  preReserveProducts: PreOrderProduct[];
};

export const PreOrderSizeAndColorTable = (props: Props) => {
  const { preReserveProducts } = props;
  const useStyles = makeStyles({
    table: {
      width: "100%",
      borderCollapse: "collapse",
      fontSize: 12,
      color: "rgb(80, 80, 80)",
    },
    th: {
      border: "1px solid #ddd",
      textAlign: "center",
      fontWeight: "normal",
      padding: "0 4px",
    },
    td: {
      border: "1px solid #ddd",
      textAlign: "center",
      padding: "0 4px",
    },
    quantityCell: {
      textAlign: "center",
    },
  });
  const classes = useStyles();
  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes.th}>カラー</th>
          <th className={classes.th}>サイズ</th>
          <th className={classes.th}>数量</th>
        </tr>
      </thead>
      <tbody>
        {preReserveProducts.flatMap((product) =>
          product.detail.map((detail, index) => (
            <tr key={`${product.color}-${detail.size}`}>
              {index === 0 && (
                <td className={classes.td} rowSpan={product.detail.length}>
                  {product.color}
                </td>
              )}
              <td className={classes.td}>{detail.size}</td>
              <td className={`${classes.td} ${classes.quantityCell}`}>
                {detail.selectedQuantity}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

import { Route, Switch } from "react-router-dom";
import Home from "./templates/Home";
import { SignUp } from "./templates/Auth/SignUp";
import { SignIn } from "./templates/Auth/SignIn";
import { Auth } from "./Auth";
import { Top } from "./templates/Top";
import MethodTest from "./templates/MethodTest";
import { DeliveryAddressReg } from "./components/DeliveryReg/DeliveryAddressReg";
import { UserProfile } from "./components/MyPage/UserProfile";
import { CreatorProfile } from "./components/Creator/CreatorProfile";
import { CommentArea } from "./templates/Post/CommentArea";
import DeliveryDone from "./components/DeliveryReg/DeliveryDone";
import CreditDone from "./components/Payment/CreditDone";
import { EditAddress } from "./components/DeliveryReg/EditAddress";
import { InquiryForm } from "./components/Menu/Inquiry/InquiryForm";
import { InquiryDone } from "./components/Menu/Inquiry/InquiryDone";
import { ProjectsCard } from "./templates/Post/ProjectPost/ProjectsCard";
import { MentionPostToList } from "./templates/Post/PostUIKit/MentionPostToList";
import { ProductSales } from "./templates/Product/ProductSales";
import { ReserveConfirm } from "./templates/Product/ReserveConfirm";
import { CreditList } from "./components/Payment/CreditList";
import CreditDeleteDone from "./components/Payment/CreditDeleteDone";
import { ReserveDone } from "./templates/Product/ReserveParts/ReserveDone";
import DeliveryDeleteDone from "./components/DeliveryReg/DeliveryDeleteDone";
import { ProductList } from "./templates/Product/ProductList";
import { SwitchProfile } from "./components/MyPage/SwitchProfile";
import { CreatorImageDone } from "./components/Creator/CreatorImageDone";
import { SocialInfo } from "./components/MyPage/SocialInfo";
import { ProfileEdit } from "./components/MyPage/ProfileEdit";
import { DeliveryReg } from "./components/DeliveryReg/DeliveryReg";
import { ReserveList } from "./templates/Product/ReserveList";
import { History } from "./components/History/History";
import { MeMerImageLayout } from "./components/Creator/CreatorImageLayout";
import { Terms } from "./components/Support/Terms";
import TermsOfService from "./components/Support/TermsOfService";
import PrivacyPolicy from "./components/Support/PrivacyPolicy";
// import { InfoNoticeSetting } from "./components/MyPage/Info/InfoNoticeSetting";
import CreatorHopeReg from "./components/MyPage/CreatorHope/CreatorHopeReg";
import CreatorHopeDone from "./components/MyPage/CreatorHope/CreatorHopeDone";
import { MeMerSalesConfirmation } from "./components/Creator/CreatorSalesConfirmation";
import { PaymentCheckoutWrapper } from "./templates/PaymentCheckoutWrapper";
import { PasswordReset } from "./templates/Auth/PasswordReset";
import { SelfPasswordReset } from "./templates/Auth/SelfPasswordReset";
import { SignUpConfirm } from "./templates/Auth/SignUpConfirm";
import { ReserveProductList } from "./templates/Product/ReserveProductList";
import { SaleProductList } from "./templates/Product/SaleProductList";
import CartDone from "./templates/Done/CartDone";
import Cart from "./templates/Cart/Cart";
import CartPurchaseConfirm from "./templates/Cart/CartPurchaseConfirm";
import CartBuyDone from "./templates/Done/CartBuyDone";
import { FollowerList } from "./components/MyPage/FollowerList";
import { FollowList } from "./components/MyPage/FollowList";
import ProjectSales from "./components/Creator/ProjectSales";
import RequestDone from "./templates/Done/RequestDone";
import BuyDone from "./templates/Done/BuyDone";
import { SelectImages } from "./templates/Post/SelectImages";
import { Menu } from "./components/Menu/Menu";
import { CreatorList } from "./components/CreatorList/CreatorList";
import { WantedProjectPosts } from "./templates/Post/ProjectPost/WantedProjectPosts";
import { SwitchMyProfile } from "./components/MyProfile/SwitchMyProfile";
import { CreatorProfileEdit } from "./components/Creator/CreatorProfileEdit";
import { PostInput } from "./templates/Post/PostInput";
import { WantPage } from "./components/WantPage/WantPage";
import ReservedProductInquiry from "./components/Creator/SaleParts/ReservedProductInquiry";
import { ReviewForm } from "./components/review/ReviewForm";
import { ReviewDone } from "./components/review/ReviewDone";
import { ReviewList } from "./components/review/ReviewList";
import { ReservationDeliveryPaymentSetup } from "./components/reservationDeliveryPaymentSetup/ReservationDeliveryPaymentSetup";

const Router = () => {
  return (
    <Auth>
      <Switch>
        {/* ホーム */}
        <Route exact path="(/?)" component={Top} />
        <Route path="/projects/:id" component={ProjectsCard} />
        {/* TODO: ProjectsCard更新ボタンでエラー出る */}
        {/* ユーザー */}
        <Route path="/creator-list" component={CreatorList} />
        <Route path="/creator/:id" component={CreatorProfile} />
        <Route path="/profile/:id/:uid" component={SwitchProfile} />
        <Route path="/profile/:id" component={SwitchProfile} />
        <Route path="/my-profile/:id" component={SwitchMyProfile} />
        <Route path="/follower/:id" component={FollowerList} />
        <Route path="/follow/:id" component={FollowList} />
        {/* ProjectのPost */}
        <Route
          path="/project-post/:creatorUid/:projectId"
          component={WantedProjectPosts}
        />
        {/* ほしいを押したProject */}
        <Route path="/wanted-project/:uid" component={WantPage} />
        {/* 商品購入系 */}
        <Route
          exact
          path="/product/reserve/setup"
          component={ReservationDeliveryPaymentSetup}
        />
        <Route path="/product/:uid/:id" component={ProductSales} />
        <Route exact path="/product/reserve" component={ReserveConfirm} />
        <Route exact path="/productlist" component={ProductList} />
        <Route exact path="/saleproductlist" component={SaleProductList} />
        <Route
          exact
          path="/reserveproductlist"
          component={ReserveProductList}
        />
        {/* error handling Not compatible */}
        <Route path="/cart/:id" component={Cart} />
        {/* error handling Not compatible */}
        <Route path="/:id/purchase/confirm" component={CartPurchaseConfirm} />
        {/* 新規登録画面 */}
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/selfpasswordreset" component={SelfPasswordReset} />
        <Route exact path="/signup/confirm" component={SignUpConfirm} />
        {/* ログイン画面 */}
        <Route exact path="/signin" component={SignIn} />
        {/* 住所登録 */}
        <Route
          exact
          path="/address-registration/:uid"
          component={DeliveryAddressReg}
        />
        <Route exact path="/editaddress/:aid" component={EditAddress} />
        {/* メソッドテスト用 */}
        <Route exact path="/methodtest" component={MethodTest} />
        <Route exact path="/dev" component={Home} />
        {/* トップページ */}
        <Route exact path="/menu" component={Menu} />
        {/* マイページの中身系 */}
        <Route exact path="/profile" component={UserProfile} />
        <Route path="/mypage/:id/profileedit" component={ProfileEdit} />
        <Route exact path="/mypage/delivery-list" component={DeliveryReg} />
        <Route exact path="/mypage/:uid/creditlist" component={CreditList} />
        <Route exact path="/mypage/reservelist" component={ReserveList} />
        <Route exact path="/mypage/history" component={History} />
        <Route
          path="/mypage/creator/profile/:uid/edit"
          component={CreatorProfileEdit}
        />
        <Route
          path="/mypage/creator/image-layout/:uid/edit"
          component={MeMerImageLayout}
        />
        <Route
          path="/mypage/creator/sale-confirmation/:uid"
          component={MeMerSalesConfirmation}
        />
        <Route
          path="/mypage/creator/:uid/saleconfimation/:pid"
          component={ProjectSales}
        />
        <Route exact path="/passwordreset" component={PasswordReset} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/terms/service" component={TermsOfService} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        {/* <Route exact path="/info/setting" component={InfoNoticeSetting} /> */}
        <Route exact path="/creatorhope" component={CreatorHopeReg} />
        {/* 完了画面系 */}
        <Route exact path="/deliverydone" component={DeliveryDone} />
        <Route
          exact
          path="/delivery/deletedone"
          component={DeliveryDeleteDone}
        />
        <Route exact path="/creditdone" component={CreditDone} />
        <Route exact path="/credit/deletedone" component={CreditDeleteDone} />
        <Route exact path="/inquirydone" component={InquiryDone} />
        <Route exact path="/reservedone" component={ReserveDone} />
        <Route exact path="/cartbuydone" component={CartBuyDone} />
        <Route exact path="/CreatorProfiledone" component={CreatorImageDone} />
        <Route exact path="/creatorhope/done" component={CreatorHopeDone} />
        <Route exact path="/cartdone" component={CartDone} />
        <Route exact path="/requestdone" component={RequestDone} />
        <Route exact path="/buydone" component={BuyDone} />
        <Route exact path="/review-done" component={ReviewDone} />
        {/********************************Social**********************************/}
        <Route exact path="/post/mention-to" component={MentionPostToList} />
        <Route exact path="/post/input" component={PostInput} />
        <Route exact path="/post/select-image" component={SelectImages} />
        <Route exact path="/comment" component={CommentArea} />
        <Route exact path="/info" component={SocialInfo} />
        <Route exact path="/inquiry" component={InquiryForm} />
        <Route exact path="/memerimageedit" component={CreatorProfileEdit} />
        <Route
          path="/mypage/creator/reserved/:uid"
          component={ReservedProductInquiry}
        />
        <Route
          path="/review-post/:creator_uid/:project_id/:purchase_history_id"
          component={ReviewForm}
        />
        <Route path="/review/:creator_uid" component={ReviewList} />

        {/********************************Payment**********************************/}
        <Route
          path="/:uid/payment/register"
          component={PaymentCheckoutWrapper}
        />
      </Switch>
    </Auth>
  );
};

export default Router;

import dayjs from "dayjs";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { ReservePriceTotal } from "./ReservePriceTotal";
import { ReserveSend } from "./ReserveSend";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { Control, Controller } from "react-hook-form";
import { Text } from "../../../components/atoms/Text/Text";
import { SelectedProduct } from "../../../Types/productType";

type Props = {
  control: Control<
    {
      couponCode: string;
    },
    any
  >;
  getValues: () => void;
  isNotExist?: boolean;
  paymentAmount: number;
  discountedPrice: number;
  discountRate: number;
  selectedProduct: SelectedProduct;
};

export const ProductShip = (props: Props) => {
  const {
    control,
    getValues,
    isNotExist,
    paymentAmount,
    discountedPrice,
    discountRate,
    selectedProduct,
  } = props;

  const useStyles = makeStyles({
    dummyArea: {
      height: 20,
    },
    productDetail: {
      display: "flex",
      height: 170,
    },
    left: {
      width: 180,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 135,
      },
    },
    right: {
      width: 320,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 240,
      },
    },
    imageArea: {
      margin: "0 auto",
      width: 120,
    },
    image: {
      width: 120,
      height: 150,
      objectFit: "cover",
    },
    shipText: {
      margin: "20px 0 10px",
    },
    couponInfo: {
      width: 320,
      margin: "2px auto",
    },
    notExistCouponInfo: {
      width: 320,
      margin: "0 auto",
      "@media screen and (max-width: 360px)": {
        width: 260,
      },
    },
    couponArea: {
      width: 320,
      height: 50,
      margin: "0 auto",
      display: "flex",
      alignItems: "center",
      "@media screen and (max-width: 360px)": {
        width: 260,
      },
    },
    couponInput: {
      width: "100%",
      marginRight: 10,
      marginBottom: 17,
      "@media screen and (max-width: 360px)": {
        width: "calc(100% - 64px)",
      },
    },
  });
  const classes = useStyles();

  const shipDate = new Date(selectedProduct.toBeShipDate);
  const day = dayjs(shipDate);
  const date = day.format("DD");

  const seasonSelect = (date: number) => {
    if (date <= 10) {
      return "上旬";
    } else if (date <= 20) {
      return "中旬";
    } else {
      return "下旬";
    }
  };
  const season = seasonSelect(Number(date));
  const { inProduction, sale, underReservation } = selectedProduct.salesStatus;
  const shipSeason = day.format("YYYY年M月") + season;

  const couponResult = () => {
    if (isNotExist === undefined) {
      return (
        <div className={classes.couponInfo}>
          <Text
            align="center"
            text="クーポンをお持ちの方は入力してください。"
          />
        </div>
      );
    }
    if (isNotExist) {
      return (
        <div className={classes.notExistCouponInfo}>
          <Text color="#E83820" text="⚠️ 存在しないクーポンです。" />
        </div>
      );
    }
    return (
      <div className={classes.notExistCouponInfo}>
        <Text text="✅ クーポンが適用されました。" />
      </div>
    );
  };

  return (
    <div>
      <SpaceBox height={8} />
      <ReservePriceTotal
        paymentAmount={paymentAmount}
        discountedPrice={discountedPrice}
        discountRate={discountRate}
        isNotExist={isNotExist}
        quantity={selectedProduct.selectedQuantityTotal}
      />
      <SpaceBox height={8} />
      <div className={classes.couponArea}>
        <Controller
          name="couponCode"
          control={control}
          render={({ field }) => (
            <TextField
              label="クーポンコード"
              autoComplete="given-name"
              fullWidth
              className={classes.couponInput}
              InputLabelProps={{
                style: { fontSize: 12 },
              }}
              {...field}
            />
          )}
        />
        <GrayButton
          className={{
            height: 30,
            width: 180,
            fontSize: 12,
          }}
          onClick={() => getValues()}
        >
          クーポン適用
        </GrayButton>
      </div>
      {couponResult()}
      {sale && (
        <Typography align="center" className={classes.shipText}>
          発送準備整い次第発送となります
        </Typography>
      )}
      {(underReservation || inProduction) && (
        <ReserveSend shipSeason={shipSeason} />
      )}
    </div>
  );
};

import React, { HTMLInputTypeAttribute } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ControllerRenderProps } from "react-hook-form";

type Props = {
  id?: string;
  infoLabel: string;
  labelRed?: string;
  place: string;
  field?: ControllerRenderProps<any, any>;
  error?: { message?: string };
  autoComplete?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?:
    | "email"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
};

export const DeliveryInput = React.memo((props: Props) => {
  const {
    id,
    infoLabel,
    labelRed,
    place,
    field,
    error,
    autoComplete,
    type,
    inputMode,
  } = props;
  const useStyles = makeStyles({
    inputContainer: {
      width: "100%",
    },
    textInfo: {
      height: 20,
      "& p": {
        fontSize: 12,
        marginLeft: 5,
      },
    },
    labelBlack: {
      color: "block",
    },
    labelRed: {
      color: "#FF6666",
      fontSize: 10,
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      <div className={classes.textInfo}>
        <Typography>
          {infoLabel}
          <span className={classes.labelRed}>{labelRed}</span>
          {error && <span className={classes.labelRed}>{error.message}</span>}
        </Typography>
      </div>
      <TextField
        id={id}
        placeholder={place}
        inputMode={inputMode}
        inputProps={{ "aria-label": "naked", style: { fontSize: 12 } }}
        fullWidth
        autoComplete={autoComplete}
        InputLabelProps={{
          style: { fontSize: 12 },
        }}
        type={type}
        {...field}
      />
    </div>
  );
});

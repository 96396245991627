import { Modal, Backdrop, Fade } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../components/UIKit";
import { selectUser } from "../../features/userSlice";
import { CartProduct } from "../../Types/productType";
import CartWithPurchaseConfirm from "./CartWithPurchaseConfirm";
import { makeStyles } from "@material-ui/core";
import { db } from "../../firebase";
import { deleteCart } from "../../features/cartOperation";
import { ProductType } from "../../Types/projectType";
import { deliveryOrderNumber } from "../../features/helpers/deliveryHelper";
import { fireStoreBatchError, stripeError } from "../../lib/errorText";
import { logError } from "../../lib/logError";
import { toast } from "react-toastify";
import {
  sendBuyProduct,
  stripeRetrievePaymentIntent,
  stripeIntentPaymentMethod,
  slackNotice,
} from "../../function/cloudFunctions";
import { SlackSendData } from "../../Types/slackType";
import { Credit } from "../../Types/paymentType";
import { doc, getDoc } from "firebase/firestore";
import { Address } from "../../Types/userType";

type Props = {
  deliveryAddress: Address;
  useCredit: Credit;
  customerID: string;
  setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
  cartData: CartProduct[];
};

type PurchaseProcess = {
  price: number;
  tax: number;
  quantity: number;
  productRef: string;
  projectRef: string;
};

const CartPurchaseGraySubmit = (props: Props) => {
  const useStyles = makeStyles({
    grayBack: {
      backgroundColor: "rgb(234,234,234)",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const { deliveryAddress, useCredit, customerID, setSubmitLoading, cartData } =
    props;
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { push } = useHistory();

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const buySubmit = async () => {
    setOpen(false);
    setSubmitLoading(true);

    const uid = user.uid;
    // stock判定
    const boolBox: boolean[] = [];
    cartData.map(async (cartItem: CartProduct) => {
      const productRef = doc(db, cartItem.productRef);
      await getDoc(productRef).then((product) => {
        const productData = product.data() as ProductType;
        if (productData.stock >= cartItem.quantity) {
          boolBox.push(true);
        } else if (productData.stock === 0) {
          dispatch(deleteCart(cartItem.cartRef));
        } else {
          boolBox.push(false);
        }
      });
    });

    if (!boolBox.includes(false)) {
      // const productDataBox: PurchaseProcess[] = await Promise.all(
      //   cartData.map(async (cartItem: CartProduct) => {
      //     const excludingTax = Math.round(cartItem.retailPrice * 0.909);
      //     const tax = cartItem.retailPrice - excludingTax;
      //     const productData = {
      //       price: excludingTax,
      //       tax: tax,
      //       quantity: cartItem.quantity,
      //       productRef: cartItem.productRef,
      //       projectRef: cartItem.projectRef,
      //     };
      //     return productData;
      //   })
      // );
      // // ここまでstock判定
      // // totalの金額計算
      // let cartTotalPrice = 0;
      // productDataBox.map((pd: PurchaseProcess) => {
      //   const total = (pd.price + pd.tax) * pd.quantity;
      //   cartTotalPrice += total;
      // });
      // const shipFee = 500; //今は適当
      // const orderNumber = await deliveryOrderNumber();
      // const { address, zipcode, phoneNumber, name } =
      //   await fetchDeliveryLocationData(uid, deliveryAddress.aid);
      // const intentMetadata = {
      //   aid: deliveryAddress.aid,
      //   postage: 500,
      //   productData: JSON.stringify(productDataBox),
      //   uid,
      //   zipcode,
      //   address,
      //   phoneNumber,
      //   name,
      //   orderNumber,
      //   totalAmount: cartTotalPrice,
      // };
      // const intentData = {
      //   amount: cartTotalPrice + shipFee,
      //   customerID: customerID,
      //   paymentMethodID: useCredit.pmID,
      //   productData: productDataBox,
      //   metadata: intentMetadata,
      // };
      // let pid;
      // try {
      //   const paymentIntentResponse: any = await stripeIntentPaymentMethod(
      //     intentData
      //   );
      //   pid = paymentIntentResponse.data;
      //   if (paymentIntentResponse.data.statusCode)
      //     throw Error(stripeError.payment.authorization);
      //   const buyData = {
      //     totalAmount: cartTotalPrice,
      //     aid: deliveryAddress.aid,
      //     pid,
      //     postage: shipFee,
      //     productData: productDataBox,
      //     uid,
      //     orderNumber,
      //   };
      //   const buyRes: any = await sendBuyProduct(buyData);
      //   // batchでcommitした数の配列が返ってくることを期待しているので、配列がなければエラー
      //   if (buyRes.data.isArray) throw Error(fireStoreBatchError.notArray);
      // } catch (error) {
      //   logError(error);
      //   if (error instanceof Error) {
      //     if (error.message === stripeError.payment.authorization) {
      //       toast.error("購入に失敗しました、もう一度お試しください。");
      //       setSubmitLoading(false);
      //       return;
      //     }
      //     if (error.message === fireStoreBatchError.notArray) {
      //       const retrieveRes = await stripeRetrievePaymentIntent({
      //         pid,
      //       });
      //       const rebornData = retrieveRes.data;
      //       const {
      //         postage,
      //         orderNumber,
      //         productData,
      //         totalAmount,
      //         ...omitData
      //       } = rebornData;
      //       const buyData = {
      //         pid,
      //         postage: parseInt(postage),
      //         productData: JSON.parse(productData ?? ""),
      //         orderNumber: parseInt(orderNumber),
      //         totalAmount: parseInt(totalAmount ?? "0"),
      //         ...omitData,
      //       };
      //       const buyRes: any = await sendBuyProduct(buyData);
      //       // TODO:方向性決まったら書き換える
      //       if (buyRes.data.isArray) {
      //         const data: SlackSendData = {
      //           scene: "error",
      //           channel: "#error_notice",
      //           id: pid,
      //           description: {
      //             error: {
      //               message: `ID:${pid}を使用してStripeから予約履歴のmetaDataをFirestoreへ保存してください`,
      //             },
      //           },
      //         };
      //         slackNotice(data);
      //         return;
      //       }
      //     }
      //   }
      // }
      // push("/cartbuydone");
    }
  };

  const deliveryAndCreditSelectCheck = () => {
    if (useCredit.pmID !== "" && deliveryAddress.aid !== "") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={classes.grayBack}>
      <PrimaryButton
        width={300}
        margin={"0 auto"}
        display={"block"}
        label={"購入する"}
        disabled={deliveryAndCreditSelectCheck()}
        borderRadius={15}
        onClick={() => handleOpen()}
      />
      {/* <FullWindowCircleLoading isActive={purchaseLoading} text={"購入処理中"} /> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <CartWithPurchaseConfirm
            buySubmit={buySubmit}
            handleClose={handleClose}
            cartData={cartData}
          />
        </Fade>
      </Modal>
    </div>
  );
};

export default CartPurchaseGraySubmit;

import { makeStyles, Typography } from "@material-ui/core";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  label: string;
  price: string;
};

export const ReservePriceDetail = (props: Props) => {
  const { label, price } = props;
  const useStyles = makeStyles({
    detail: {
      display: "flex",
      width: 320,
    },
    right: {
      flex: 1,
      paddingRight: 8,
    },
    left: {
      flex: 1,
      paddingLeft: 8,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.detail}>
      <div className={classes.left}>
        <Text text={label} isDark align="left" />
      </div>
      <div className={classes.right}>
        <Text text={price} isDark align="right" />
      </div>
    </div>
  );
};

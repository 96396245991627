import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

type Props = {
  id?: string;
  infoLabel: string;
  labelRed: string;
  place: string;
  field: any;
  error?: any;
  disabled?: boolean;
  labelBlack?: string;
  helperText?: string;
};

export const CityInput = (props: Props) => {
  const {
    id,
    infoLabel,
    labelRed,
    place,
    field,
    error,
    disabled,
    labelBlack,
    helperText,
  } = props;
  const useStyles = makeStyles({
    inputContainer: {},
    textInfo: {
      height: 20,
      "& p": {
        fontSize: 13,
        margin: "0 0 0 5px",
      },
    },
    textInput: {
      height: 20,
    },
    textField: {
      margin: "",
      padding: "0 0 0 0 ",
    },
    labelBlack: {
      color: "block",
    },
    labelRed: {
      color: "#FF6666",
      fontSize: 10,
    },
    disabledInput: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 1)", // ここで disabled 状態の文字色を設定
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      <div className={classes.textInfo}>
        <Typography>
          {infoLabel}
          <span className={classes.labelRed}>{labelRed}</span>
          <span className={classes.labelBlack}>{labelBlack}</span>
          {error && <span className={classes.labelRed}>{helperText}</span>}
        </Typography>
      </div>
      <TextField
        placeholder={place}
        inputProps={{
          "aria-label": "naked",
          readOnly: true,
          style: { fontSize: 12 },
        }}
        InputLabelProps={{
          style: { fontSize: 12 },
        }}
        fullWidth
        disabled={disabled}
        id={id}
        {...field}
        className={disabled ? classes.disabledInput : ""}
      />
    </div>
  );
};

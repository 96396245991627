import { makeStyles } from "@material-ui/core";
import { Text } from "../atoms/Text/Text";

type Props = {
  label: string;
};
export const DeliveryUserInfo = (props: Props) => {
  const { label } = props;
  const useStyles = makeStyles({
    deliveryInfo: {
      marginLeft: 20,
     
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.deliveryInfo}>
      <Text isDark text={label} />
    </div>
  );
};

import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { address, creditList, useCreditUtil } from "../../features/paymentUtil";
import { Credit } from "../../Types/paymentType";
import { CartProduct } from "../../Types/productType";
import CartDetaile from "./CartDetaile";
import { loadStripe } from "@stripe/stripe-js/pure";
import { fetchDeliveryAddress } from "../../features/addressOperation";
import { selectPurchase } from "../../features/purchaseSlice";
import { Typography, makeStyles } from "@material-ui/core";
import ReserveDeliveryDate from "../Product/ReserveParts/ReserveDeliveryDate";
import { ReserveGrayBack } from "../Product/ReserveParts/ReserveGrayBack";
import LoadingOverlay from "react-loading-overlay-ts";
import styled from "styled-components";
import CartPurchaseGraySubmit from "../Purchase/CartPurchaseGraySubmit";
import FullWindowCircleLoading from "../../components/UIKit/FullWindowCircleLoading";
import { PurchaseDetail } from "../Purchase/PurchaseDetail";
import { stripeRetrievePaymentMethod } from "../../function/cloudFunctions";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { GrayBack } from "../../components/MyPage/GrayBack";
import { getStripePublicKey } from "../../function/common";
import { logError } from "../../lib/logError";
import { Address } from "../../Types/userType";

const StyledCircle = styled(LoadingOverlay)`
  .MyLoader_overlay {
    margin: 0 auto;
    width: 500px;
    height: ${window.innerHeight}px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(145, 145, 145, 0.9);
    z-index: 10;
    @media screen and (max-width: 499px) {
      width: 375px;
    }
  }
  .MyLoader_spinner {
    width: 70px;
  }
  .MyLoader_wrapper {
  }
  .MyLoader_content {
  }
`;

const stripePromise = loadStripe(getStripePublicKey());
const CartPurchaseConfirm = (props: RouteComponentProps<{ id: string }>) => {
  const { history, location } = props;
  const useStyles = makeStyles({
    returnText: {
      color: "#0c77ed",
      borderBottom: "1px solid #0c77ed",
      width: 50,
      margin: "0 auto",
      cursor: "pointer",
      "&:hover": {
        color: "#6877c0",
      },
    },
  });
  const classes = useStyles();
  const cartData = history.location.state as CartProduct[];
  const dispatch = useDispatch();
  const deleteBoolLength = location.pathname.split("/").length;
  //住所を格納
  const [deliveryAddress, setDeliveryAddress] = useState<Address>(address);

  // StripeからcardListを取得して格納
  const [useCreditList, setUseCreditList] = useState<Credit[]>(creditList);
  // 選択しているcard
  const [useCredit, setUseCredit] = useState<Credit>(useCreditUtil);
  // クレジットカードのLoading
  const [creditActive, setCreditActive] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);
  // カートからページ切替時の初期ローディング
  const [initLoading, setInitLoading] = useState(true);
  // エラー分隠すためのstate
  const [hide, setHide] = useState(false);
  // credit取得時のタイムオーバー時使う
  const [timeOver, setTimeOver] = useState(false);

  const customerID = useSelector(selectPurchase);

  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
      setHide(true);
    }, 500);

    let fiveCount = () => {
      setTimeOver(true);
      setCreditActive(false);
    };

    let timeOverMethod = setTimeout(fiveCount, 5000);

    let isMounted: boolean = true;

    dispatch(fetchDeliveryAddress(setDeliveryAddress));

    let cardDataBox: Array<Credit> = [] as Credit[];

    if (customerID !== "") {
      stripeRetrievePaymentMethod({ customerID: customerID })
        .then((res) => {
          if (isMounted) {
            res.data.data.forEach((_: any, i: number) => {
              const data = res.data.data[i].card;
              const metaData = res.data.data[i];
              const pmID = res.data.data[i].id;
              const cardData = {
                last4: data?.last4 ?? "",
                brand: data?.brand ?? "",
                expMonth: data?.exp_month ?? 0,
                expYear: data?.exp_year ?? 0,
                primaryCard: metaData?.metadata?.primary_card === "true",
                pmID: pmID ?? "",
              };
              cardDataBox.push(cardData);
            });
            setCreditActive(false);
            setUseCreditList(cardDataBox);
            clearTimeout(timeOverMethod);
          }
        })
        .catch((err) => {
          logError(err);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div>
      <BrowserBackHeader label="購入手続き" browserBack={history} />
      <FullWindowCircleLoading isActive={initLoading} text={"読込中"} />
      {/* <StyledCircle
        active={initLoading}
        spinner
        text={"読み込み中"}
        classNamePrefix="MyLoader_"
      ></StyledCircle> */}
      <FullWindowCircleLoading isActive={submitLoading} text={"注文処理中"} />
      {/* <StyledCircle
        active={submitLoading}
        spinner
        text={"注文処理中"}
        classNamePrefix="MyLoader_"
      ></StyledCircle> */}
      <SpaceBox height={50} />
      {deliveryAddress.aid !== "" ? (
        <>
          <CartDetaile
            cartData={cartData}
            deleteBoolLength={deleteBoolLength}
          />
          <SpaceBox height={50} />
          <Elements stripe={stripePromise}>
            <PurchaseDetail
              address={deliveryAddress}
              setAddress={setDeliveryAddress}
              useCreditList={useCreditList}
              useCredit={useCredit}
              setUseCredit={setUseCredit}
              creditActive={creditActive}
              timeOver={timeOver}
            />
          </Elements>
          <ReserveGrayBack label={"お届け日時"} />
          <ReserveDeliveryDate />
          <GrayBack height={45} />
          <CartPurchaseGraySubmit
            deliveryAddress={deliveryAddress}
            useCredit={useCredit}
            customerID={customerID}
            setSubmitLoading={setSubmitLoading}
            cartData={cartData}
          />
          <GrayBack height={70} />
        </>
      ) : (
        <>
          {hide && (
            <>
              <SpaceBox height={200} />
              <Typography align="center" variant="h6">
                配送先のメイン設定をしていないか
              </Typography>
              <Typography align="center" variant="h6">
                登録がされていません
              </Typography>
              <Typography align="center" variant="h6">
                申し訳ありませんが、戻ってやり直してください
              </Typography>
              <SpaceBox height={100} />
              <Typography
                align="center"
                variant="h6"
                className={classes.returnText}
                onClick={() => history.goBack()}
              >
                戻る
              </Typography>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CartPurchaseConfirm;

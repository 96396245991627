import { countUpOrderNumber } from "../../function/cloudFunctions";

export const deliveryOrderNumber = async () => {
  const orderNumber = await countUpOrderNumber();
  return orderNumber.data;
};

export const generateDeliveryName = (
  lastName: string,
  firstName: string,
  lastNameKana?: string,
  firstNameKana?: string
) => {
  if (lastNameKana && firstNameKana)
    return `${lastName} ${firstName} (${lastNameKana} ${firstNameKana})`;

  if (lastNameKana || firstNameKana)
    return `${lastName} ${firstName} (${lastNameKana ?? ""}${
      firstNameKana ?? ""
    })`;

  return `${lastName} ${firstName}`;
};

export const splitZipCode = (zipCode: string) => {
  return `〒 ${zipCode.slice(0, 3)}-${zipCode.slice(3)}`;
};

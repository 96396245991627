import { auth } from "../firebase/index";
import { push } from "connected-react-router";
import { AppThunk } from "../app/store";
import { Credit } from "../Types/userType";
import { Credit as CREDIT } from "../Types/paymentType";
import { creditsCollection, creditsDocument } from "../firebasePaths";
import { deleteDoc, getDocs, setDoc } from "firebase/firestore";
import { stripeRetrievePaymentMethod } from "../function/cloudFunctions";

export const sendAllFalse = (creditInfo: Omit<Credit, "cid">): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const creditsRef = creditsCollection(uid);
    if (creditInfo.mainCredit) {
      getDocs(creditsRef)
        .then((doc) => {
          doc.forEach((data) => {
            const credit = data.data();
            const allFalse: Pick<Credit, "mainCredit"> = {
              mainCredit: false,
            };
            setDoc(creditsDocument(uid, credit.cid), allFalse, { merge: true });
          });
        })
        .then(() => {
          dispatch(sendCredit(creditInfo));
        });
    } else if (creditInfo.mainCredit === false) {
      dispatch(sendCredit(creditInfo));
    }
  };
};

export const sendCredit = (creditInfo: Omit<Credit, "cid">): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const creditRef = creditsDocument(uid);

    setDoc(creditRef, { ...creditInfo, cid: creditRef.id }).then(() => {
      dispatch(push("/creditdone"));
    });
  };
};

export const creditLengthCheck = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const creditsRef = creditsCollection(uid);
    const creditsLength = (await getDocs(creditsRef)).size;
    if (creditsLength >= 5) {
      alert(`クレジット登録は5つまでです。`);
    } else {
      dispatch(push("/cre"));
    }
  };
};

export const updateMainCredit = (credit: Credit[]): AppThunk => {
  return async (): Promise<void> => {
    const uid = auth.currentUser!.uid;
    credit.forEach((c: Credit) => {
      const creditRef = creditsDocument(uid, c.cid);
      setDoc(creditRef, { mainCredit: c.mainCredit }, { merge: true });
    });
  };
};

export const fetchCredits = (uid: string, setCredits: Function): AppThunk => {
  let idx = 1;
  return async (): Promise<void> => {
    const creditsRef = creditsCollection(uid);
    getDocs(creditsRef).then((snapshot) => {
      snapshot.forEach((doc) => {
        const credit = doc.data();
        const newBody: Credit = {
          cid: credit.cid,
          cardName: credit.cardName,
          creditNumber: credit.creditNumber,
          securityCode: credit.securityCode,
          expiryMonth: credit.expiryMonth,
          expiryYear: credit.expiryYear,
          mainCredit: credit.mainCredit,
          creditIdx: String(idx),
        };
        idx++;
        setCredits((prev: Credit[]) => [...prev, newBody]);
      });
    });
  };
};

export const deleteCredit = (cid: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const creditRef = creditsDocument(uid, cid);
    deleteDoc(creditRef)
      .then(() => {
        dispatch(push("/credit/deletedone"));
      })
      .catch((error) => {
        console.error("Failed to delete document:", error);
      });
  };
};

export const fetchUseCredit = (setCredit: Function): AppThunk => {
  return async (): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const creditsRef = creditsCollection(uid);

    getDocs(creditsRef).then((snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.mainCredit) {
          setCredit(data);
        }
      });
    });
  };
};

export const fetchUseCreditChange = (setChangeCredit: Function): AppThunk => {
  return async (): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const creditsRef = creditsCollection(uid);
    let creditBox: any[] = [];

    getDocs(creditsRef)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          creditBox.push(data);
        });
      })
      .then(() => {
        setChangeCredit(creditBox);
      });
  };
};

export const fetchCustomerId = async (
  setCustomerId: React.Dispatch<React.SetStateAction<string>>,
  setIsCreditLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCreditCreationMode: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      const ref = creditsCollection(user.uid);

      getDocs(ref).then((snapshot) => {
        if (snapshot.size === 0) {
          setIsCreditLoading(false);
          setIsCreditCreationMode(true);
        }
        snapshot.forEach((cusData) => {
          const data = cusData.data();
          setCustomerId(data.stripeCustomerID);
        });
      });
    }
    unsubscribe();
  });
};

export const fetchCreditsWithPreOrder = async (
  customerId: string,
  setCredits: React.Dispatch<React.SetStateAction<CREDIT[]>>,
  setCredit: React.Dispatch<React.SetStateAction<CREDIT | undefined>>,
  setIsCreditCreationMode: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const result = await stripeRetrievePaymentMethod({
    customerID: customerId,
  });
  if (result.data.statusCode) throw Error();
  const credits = result.data.data.map((cre) => {
    const { card, metadata, id } = cre;
    return {
      last4: card?.last4 ?? "",
      brand: card?.brand ?? "",
      expMonth: card?.exp_month ?? 0,
      expYear: card?.exp_year ?? 0,
      primaryCard: metadata?.primary_card === "true",
      pmID: id ?? "",
    };
  });
  console.log(credits.length === 0);
  setIsCreditCreationMode(credits.length === 0);
  const mainCredit = credits.find((cre) => cre.primaryCard);
  setCredits(credits);
  setCredit(mainCredit);
};

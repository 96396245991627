import { useState } from "react";
import { ReserveProduct } from "./ReserveParts/ReserveProduct";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { Credit } from "../../Types/paymentType";
import { RouteComponentProps } from "react-router-dom";
import { BuySubmitButton } from "./ReserveParts/BuySubmitButton";
import { ProductSaleInfo } from "./ProductParts/ProductSaleInfo";
import { ProductShip } from "./ReserveParts/ProductShip";
import { Coupon, SalesProject, SelectedProduct } from "../../Types/productType";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { useForm } from "react-hook-form";
import { Loading } from "../../components/atoms/Loading/Loading";
import { Address } from "../../Types/userType";
import { SelectedAddress } from "../../components/reservationDeliveryPaymentSetup/SelectedAddress";
import { CreditCard } from "../../components/Payment/CreditCard";
import { GrayBack } from "../../components/MyPage/GrayBack";
import { PreOrderTitle } from "../../components/reservationDeliveryPaymentSetup/PreOrderTitle";
import { ReserveInfo } from "./ReserveParts/ReserveInfo";
import { Text } from "../../components/atoms/Text/Text";

export const ReserveConfirm = (props: RouteComponentProps<{ id: string }>) => {
  const { history } = props;
  const { projectData, selectedProduct, credit, address, customerId } = history
    .location.state as {
    projectData: SalesProject;
    selectedProduct: SelectedProduct;
    address: Omit<Address, "uid">;
    credit: Credit;
    customerId: string;
  };

  const {
    retailPrice,
    selectedQuantityTotal,
    toBeShipDate,
    reserveProducts,
    image,
    projectName,
    itemName,
  } = selectedProduct;

  const {
    coupons,
    reservations,
    minimumProduction,
    reserveId,
    prevLocation,
    memer,
  } = projectData;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [isNotExist, setIsNotExist] = useState<boolean>();
  const [paymentAmount, setPaymentAmount] = useState(retailPrice);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const defaultValues = {
    couponCode: "",
  };

  const methods = useForm({ defaultValues });
  const { control, getValues } = methods;

  const discountApplicable = (coupon: Coupon) => {
    setIsNotExist(false);
    // 割引実行
    const discountAmount = Math.ceil(
      retailPrice * (coupon.discountRate / 100) * selectedQuantityTotal
    );
    const discountedPrice =
      retailPrice - discountAmount / selectedQuantityTotal;

    setPaymentAmount(discountedPrice);
    setDiscountedPrice(discountAmount);
    setDiscountRate(Number(coupon.discountRate));
  };

  const couponReset = () => {
    setIsNotExist(true);
    setPaymentAmount(retailPrice);
    setDiscountedPrice(0);
    setDiscountRate(0);
  };

  const couponExistCheck = (code: string) => {
    // 一旦初期化
    setIsNotExist(undefined);
    if (!coupons) {
      setCouponCode("");
      couponReset();
      return;
    }

    const matchedCouponCode = coupons.find((coupon) => coupon.code === code);
    if (!matchedCouponCode) {
      setCouponCode("");
      couponReset();
      return;
    }

    discountApplicable(matchedCouponCode);
    setCouponCode(code);
  };

  return (
    <div>
      {toBeShipDate === "" ? (
        <>
          <BrowserBackHeader label="商品予約" browserBack={history} />
          <SpaceBox height={100} />
          <Text text="申し訳ありません。" isDark align="center" />
          <Text text="商品選択からやり直してください。" isDark align="center" />
        </>
      ) : (
        <>
          <BrowserBackHeader label="商品予約" browserBack={history} />
          {submitLoading && <Loading text="予約処理中" />}
          <GrayBack isDark height={1} />
          <PreOrderTitle title="送付先・お支払い情報" />
          <GrayBack isDark height={1} />
          <SelectedAddress address={address} />
          <CreditCard credit={credit} />
          {reservations < minimumProduction && <ProductSaleInfo />}
          {reserveProducts.map((product) =>
            product.detail.map((detail) => {
              const productData = {
                color: product.color,
                value: product.value,
              };
              return (
                <ReserveProduct
                  key={`${detail.ref}${detail.size}`}
                  productData={productData}
                  productDetail={detail}
                  image={image}
                  projectName={projectName}
                  retailPrice={retailPrice}
                  itemName={itemName}
                />
              );
            })
          )}
          <ProductShip
            control={control}
            getValues={() => {
              const couponCode = getValues("couponCode");
              couponExistCheck(couponCode);
            }}
            isNotExist={isNotExist}
            paymentAmount={paymentAmount}
            discountedPrice={discountedPrice}
            discountRate={discountRate}
            selectedProduct={selectedProduct}
          />
          {/* {choiceProduct.salesStatus.sale && (
            <>
              <ReserveGrayBack label="お届け日時" />
              <ReserveDeliveryDate />
            </>
          )} */}

          <BuySubmitButton
            deliveryAddress={address}
            selectedProduct={selectedProduct}
            useCredit={credit}
            customerID={customerId}
            setSubmitLoading={setSubmitLoading}
            reserveId={reserveId}
            isNotExist={isNotExist}
            paymentAmount={paymentAmount}
            discountedPrice={discountedPrice}
            discountRate={discountRate}
            prevLocation={prevLocation}
            creatorInfo={memer}
            couponCode={couponCode}
          />
          <ReserveInfo />
          <SpaceBox height={40} />
        </>
      )}
    </div>
  );
};

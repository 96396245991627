import React, { useState } from "react";
import { makeStyles, Switch, TextField } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { useHistory } from "react-router-dom";
import { registerCard } from "../../features/PaymentOperation";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { Loading } from "../atoms/Loading/Loading";
import { Text } from "../atoms/Text/Text";
import { SpaceBox } from "../UIKit/SpaceBox";
import visa from "../../assets/icon/VISA.png";
import master from "../../assets/icon/Master.png";
import jcb from "../../assets/icon/JCB.png";
import discover from "../../assets/icon/discover.png";
import diners from "../../assets/icon/Diner.png";
import american from "../../assets/icon/American.png";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { GrayBack } from "../MyPage/GrayBack";

type Props = {
  uid: string;
  customerId: string;
  setCustomerId: React.Dispatch<React.SetStateAction<string>>;
  setCreateCounter?: React.Dispatch<React.SetStateAction<number>>;
  salesProject?: unknown;
  isCardRegistrationPage?: boolean;
};

type FormValue = {
  cardHolderName: string;
};

export const CreditForm = (props: Props) => {
  const {
    uid,
    salesProject,
    customerId,
    setCustomerId,
    setCreateCounter,
    isCardRegistrationPage = false,
  } = props;
  const originalWidth = 65.115;
  const originalHeight = 44.379;

  const newWidth = (originalWidth * 3) / 4;
  const newHeight = (originalHeight * 3) / 4;
  const useStyles = makeStyles({
    container: {
      padding: "0 8px",
    },
    cardText: {
      margin: "20px 0",
    },
    cardKindText: {},
    inputArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    checked: {
      border: "1px solid gray",
      borderRadius: 20,
      width: "100%",
      height: 50,
      backgroundColor: "lightcyan",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    unChecked: {
      border: "1px solid gray",
      borderRadius: 20,
      width: "100%",
      height: 50,
      backgroundColor: "white",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    cardBlockFlex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    cardElementWrapper: {
      borderBottom: "1px solid rgb(151,151,151)",
      padding: "10px",
    },
    cardImage: {
      width: originalWidth,
      height: originalHeight,
      "@media screen and (max-width: 499px)": {
        width: newWidth,
        height: newHeight,
      },
    },
  });
  const classes = useStyles();
  const stripe = useStripe();
  const element = useElements();
  const { username } = useSelector(selectUser);
  const { push } = useHistory();
  const [isMain, setIsMain] = useState(false);
  const [isActive, setActive] = useState(false);
  const onSubmit = async (data: FormValue) => {
    const { cardHolderName } = data;
    setActive(true);
    const { status, customerId: stripeCustomerId } = await registerCard(
      stripe,
      element,
      customerId,
      isMain,
      cardHolderName.toUpperCase(),
      uid,
      username
    );
    if (status === "failed") return;
    setCustomerId(stripeCustomerId);
    const cardElement = element?.getElement(CardElement);
    if (cardElement) {
      cardElement.clear();
    }
    if (setCreateCounter)
      setCreateCounter((prev) => {
        return prev + 1;
      });
    toast.success("クレジットカードの登録が完了しました。");
    setActive(false);
    if (isCardRegistrationPage) push(`/mypage/${uid}/creditlist`);
  };

  const switchCheck = () => {
    setIsMain(!isMain);
  };

  const switchText = () => {
    if (isMain) return "ON";
    return "OFF";
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValue>();

  const validateName = (value: string) => {
    const nameRegex = /^[A-Za-z\s\-.']+$/;
    if (!nameRegex.test(value)) {
      return "名前には英字、スペース、ハイフン、ピリオド、アポストロフィのみ使用できます。";
    }
    return true;
  };

  const cardList = [
    {
      cardImage: visa,
      alt: "visaCard",
    },
    {
      cardImage: master,
      alt: "masterCard",
    },
    {
      cardImage: jcb,
      alt: "jcbCard",
    },
    {
      cardImage: discover,
      alt: "discoverCard",
    },
    {
      cardImage: diners,
      alt: "dinersCard",
    },
    {
      cardImage: american,
      alt: "americanCard",
    },
  ];

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isActive && <Loading text="登録中" />}
        <GrayBack
          height={20}
          label="クレジットカード所有者のお名前を入力してください"
          fontSize="12px"
          color="gray"
          backGroundColor="white"
        />
        <Controller
          name="cardHolderName"
          control={control}
          rules={{ required: "必須です", validate: validateName }}
          render={({ field }) => (
            <TextField
              placeholder="TARO TOMEME"
              {...field}
              style={{
                width: "100%",
                color: "rgb(80, 80, 80)",
              }}
              inputProps={{
                "aria-label": "naked",
                style: { fontSize: 12 },
              }}
              InputLabelProps={{
                style: { fontSize: 12 },
              }}
              type="text"
              autoComplete="cc-name"
              error={!!errors.cardHolderName}
              helperText={
                errors.cardHolderName && errors?.cardHolderName?.message
              }
            />
          )}
        />
        <GrayBack
          height={20}
          label="クレジットカードの番号を入力してください"
          fontSize="12px"
          color="gray"
          backGroundColor="white"
        />
        <div className={classes.cardElementWrapper}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: "12px",
                  color: "rgb(80, 80, 80)",
                  "::placeholder": {
                    fontSize: "12px", // プレースホルダー専用のサイズ設定
                    color: "rgb(151,151,151)", // オプション：プレースホルダーの色も変更可能
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        <SpaceBox height={20} />
        <div className={classes.inputArea}>
          <Text
            text={`メインカードに設定する(${switchText()}状態)  ※初めての登録は自動でON`}
            className={{
              TextAlign: "center",
            }}
          />
          <Switch
            onChange={() => {
              switchCheck();
            }}
            color="primary"
            value={isMain}
          />
        </div>
        <GrayBack
          height={20}
          label="登録可能カード"
          fontSize="12px"
          color="gray"
          backGroundColor="white"
        />
        <div className={classes.cardBlockFlex}>
          {cardList.map((credit) => (
            <img
              key={credit.alt}
              src={credit.cardImage}
              className={classes.cardImage}
              alt={credit.alt}
            />
          ))}
        </div>
        <SpaceBox height={30} />
        <Center>
          <GrayButton
            type="submit"
            width={250}
            className={{
              fontSize: 12,
            }}
          >
            カード情報を登録する
          </GrayButton>
        </Center>
        {salesProject && (
          <Center
            className={{
              marginTop: 20,
            }}
          >
            <GrayButton
              onClick={() => push("/product/reserve", salesProject)}
              width={250}
              className={{
                fontSize: 12,
              }}
            >
              購入画面に戻る
            </GrayButton>
          </Center>
        )}
      </form>
    </div>
  );
};

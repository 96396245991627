import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { fetchAddressChange } from "../../../features/addressOperation";
import DeliveryAddressChange from "../../../components/DeliveryReg/DeliveryAddressChange";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { selectUser } from "../../../features/userSlice";
import { useHistory } from "react-router-dom";
import { Address } from "../../../Types/userType";

type Props = {
  address: Address;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
  salesProject?: unknown;
};

export const ReserveAddress = (props: Props) => {
  const { address, setAddress, salesProject } = props;
  const dispatch = useDispatch();
  const useStyles = makeStyles({
    whiteArea: {
      width: 480,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 355,
      },
      paddingTop: 15,
      paddingLeft: 20,
    },
    infoText: {
      fontSize: 14,
    },
    changeBtn: {
      border: "1px solid gray",
      width: 75,
      margin: "10px auto 15px",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  // 000-0000にするために変換
  const { push } = useHistory();
  const zipcode = address.zipcode.slice(0, 3) + "-" + address.zipcode.slice(3);
  const userInfo = useSelector(selectUser);
  const [changeAddress, setChangeAddress] = useState();
  const [open, setOpen] = useState(false);
  const addressChange = () => {
    dispatch(fetchAddressChange(userInfo.uid, setChangeAddress));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {address.aid === "" ? (
        <>
          <div className={classes.whiteArea}>
            <Typography className={classes.infoText}>未登録</Typography>
          </div>
          <div
            className={classes.changeBtn}
            onClick={() =>
              push(`/address-registration/${userInfo.uid}`, salesProject)
            }
          >
            <Typography className={classes.infoText} align="center">
              登録する
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div className={classes.whiteArea}>
            <Typography
              className={classes.infoText}
            >{`${address.lastName} ${address.firstName} 様`}</Typography>
            <Typography
              className={classes.infoText}
            >{`〒${zipcode}`}</Typography>
            <Typography
              className={classes.infoText}
            >{`${address.prefecture}${address.city}${address.town}${address.address} ${address.buildingName}`}</Typography>
            <Typography
              className={classes.infoText}
            >{`TEL ${address.phoneNumber}`}</Typography>
          </div>
          <div className={classes.changeBtn} onClick={() => addressChange()}>
            <Typography className={classes.infoText} align="center">
              変更する
            </Typography>
          </div>
        </>
      )}
      {changeAddress && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <DeliveryAddressChange
              setAddress={setAddress}
              changeAddress={changeAddress}
              setOpen={setOpen}
            />
          </Fade>
        </Modal>
      )}
    </>
  );
};

import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { GrayButton } from "../../atoms/GrayButton/GrayButton";

type Props = {
  isShow: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  leftButtonText: string;
  leftOnClick?: () => void;
  rightButtonText: string;
  rightOnClick?: () => void;
  description: string;
};

export const NormalModal = (props: Props) => {
  const {
    isShow,
    setState,
    leftButtonText,
    leftOnClick,
    rightButtonText,
    rightOnClick,
    description,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      top: 0,
      left: 0,
      backgroundColor: "#00000040",
    },
    box: {
      width: 320,
      border: "1px solid #00000040",
      backgroundColor: "white",
      padding: "16px",
    },
    text: {
      marginTop: 30,
      fontSize: 12,
      whiteSpace: "pre-line",
      color: "rgb(100, 100, 100)",
    },
    buttonBox: {
      flexGrow: 1,
      marginTop: 32,
      display: "flex",
      justifyContent: "center",
    },
    button: {
      width: 150,
      color: "white",
      backgroundColor: "rgb(175, 175, 175)",
      borderRadius: 0,
    },
    centerContent: {
      display: "flex",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  if (isShow) {
    return (
      <div className={classes.root} onClick={() => setState(false)}>
        <div className={classes.box}>
          <Typography align="center" className={classes.text} noWrap={false}>
            {description}
          </Typography>
          <div className={classes.buttonBox}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.centerContent}>
                    <GrayButton
                      variant="contained"
                      onClick={() => {
                        if (!leftOnClick) return;
                        leftOnClick();
                      }}
                      className={{
                        fontSize: 12,
                      }}
                    >
                      {leftButtonText}
                    </GrayButton>
                  </Grid>
                  <Grid item xs={6} className={classes.centerContent}>
                    <GrayButton
                      variant="contained"
                      onClick={() => {
                        if (!rightOnClick) return;
                        rightOnClick();
                      }}
                      className={{
                        fontSize: 12,
                      }}
                    >
                      {rightButtonText}
                    </GrayButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

type Props = {
  children: ReactNode;
  className?: CSSProperties;
};

export const Center = (props: Props) => {
  const { children, className } = props;
  const useStyles = makeStyles({
    centerBlock: { display: "flex", justifyContent: "center", ...className },
  });
  const classes = useStyles();
  return <div className={classes.centerBlock}>{children}</div>;
};

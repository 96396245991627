import { makeStyles } from "@material-ui/core";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  shipSeason: string;
};

export const ReserveSend = (props: Props) => {
  const { shipSeason } = props;
  const useStyles = makeStyles({
    shipInfo: {
      margin: "16px auto",
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.shipInfo}>
      <Text text="発送予定日" align="center" isDark isBold fontSize={14} />
      <Text text={shipSeason} align="center" isDark isBold fontSize={18} />
    </div>
  );
};

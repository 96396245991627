import { Credit } from "../../Types/paymentType";
import { makeStyles } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import { CreditCard } from "../Payment/CreditCard";
type Props = {
  credits: Credit[];
  selectedCredit?: Credit;
  setCredit?: React.Dispatch<React.SetStateAction<Credit | undefined>>;
  setIsSelectCredits?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreditList = (props: Props) => {
  const { credits, selectedCredit, setCredit, setIsSelectCredits } = props;
  const useStyles = makeStyles({
    background: {
      backgroundColor: "white",
      width: 350,
      padding: 16,
      height: "100%",
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <GrayBack height={1} isDark />
      {credits.map((credit) => (
        <div
          style={{
            backgroundColor:
              selectedCredit?.pmID === credit.pmID
                ? "rgba(188, 226, 232, 0.5)"
                : "white",
          }}
        >
          <CreditCard
            credit={credit}
            isSelectMode
            setCredit={setCredit}
            isCurrent={selectedCredit?.pmID === credit.pmID}
            setIsSelectCredits={setIsSelectCredits}
          />
        </div>
      ))}
    </div>
  );
};

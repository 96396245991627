import { makeStyles, Typography } from "@material-ui/core";
import { SalesStatus } from "../../../Types/productType";

type Props = {
  status: SalesStatus;
};

export const ProjectPostStatus = (props: Props) => {
  const { status } = props;
  const colorChange = () => {
    if (status.underReservation)
      return { text: "予約受付中", color: "rgba(82, 209, 165, 0.7)" };
    if (status.production)
      return { text: "製作中", color: "rgba(237, 157, 43, 0.7)" };
    if (status.sale)
      return { text: "販売中", color: "rgba(107, 157, 243, 0.7)" };
    if (status.endOfSale)
      return { text: "Sold out", color: "rgba(220, 51, 50, 0.7)" };
    if (status.cancel)
      return { text: "Project cancel", color: "rgba(48, 49, 52, 0.7)" };
    if (status.inProduction)
      return { text: "生産中(予約可)", color: "rgba(106, 145, 253, 0.7)" };
    if (status.official) return { text: "Official", color: "rgb(215, 74, 32)" };
  };

  const useStyles = makeStyles({
    textBox: {
      textAlign: "center",
    },
    statusText: {
      width: 90,
      height: 20,
      fontSize: 10,
      background: colorChange()?.color,
      color: "#FFF",
      boxSizing: "border-box",
      zIndex: 1,
      lineHeight: 2,
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.textBox}>
      <Typography align="center" className={classes.statusText}>
        {colorChange()?.text}
      </Typography>
    </div>
  );
};

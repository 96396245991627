import { RouteComponentProps } from "react-router-dom";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { DeliveryAddressForm } from "./DeliveryAddressForm";
import { SalesProject } from "../../Types/productType";
import { makeStyles } from "@material-ui/core";

export const DeliveryAddressReg = (props: RouteComponentProps) => {
  const { history } = props;

  const salesProject = history.location.state;
  const useStyles = makeStyles({
    container: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": { width: "100vw" },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <BrowserBackHeader label="配送先登録" browserBack={history} />
      <DeliveryAddressForm
        salesProject={salesProject as SalesProject | undefined}
        isAddressRegistrationPage
      />
    </div>
  );
};

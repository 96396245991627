import LoadingOverlay from "react-loading-overlay-ts";
import styled from "styled-components";
import { ReserveAddress } from "../Product/ReserveParts/ReserveAddress";
import { ReserveGrayBack } from "../Product/ReserveParts/ReserveGrayBack";
import { ReservePayment } from "../Product/ReserveParts/ReservePayment";
import { Credit } from "../../Types/paymentType";
import { Address } from "../../Types/userType";

type Props = {
  address: Address;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
  useCreditList: Credit[];
  useCredit: Credit;
  setUseCredit: React.Dispatch<React.SetStateAction<Credit>>;
  creditActive: boolean;
  timeOver: boolean;
  salesProject?: unknown;
};

const StyledCircle = styled(LoadingOverlay)`
  .MyLoader_overlay {
    background: rgba(234, 234, 234, 0.9);
  }
`;
export const PurchaseDetail = (props: Props) => {
  const {
    address,
    setAddress,
    useCreditList,
    useCredit,
    setUseCredit,
    creditActive,
    timeOver,
    salesProject,
  } = props;
  return (
    <>
      <ReserveGrayBack label="送付先" />
      <ReserveAddress
        address={address}
        setAddress={setAddress}
        salesProject={salesProject}
      />
      <ReserveGrayBack label="お支払い情報" />
      <StyledCircle active={creditActive} spinner classNamePrefix="MyLoader_">
        <ReservePayment
          useCreditList={useCreditList}
          useCredit={useCredit}
          setUseCredit={setUseCredit}
          timeOver={timeOver}
          salesProject={salesProject}
        />
      </StyledCircle>
    </>
  );
};

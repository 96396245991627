import { makeStyles } from "@material-ui/core";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  price: string;
  quantity: number;
};

export const ReservePrice = (props: Props) => {
  const { price, quantity } = props;
  const useStyles = makeStyles({
    priceBox: {
      display: "flex",
      width: 300,
      margin: "0 auto",
      gap: 4,
      "@media screen and (max-width: 499px)": {
        width: 200,
      },
    },
    left: {
      fontSize: 12,
      width: "50%",
    },
    right: {
      width: "50%",
    },
    price: {
      lineHeight: 3,
      fontSize: 12,
    },
    number: {
      lineHeight: 3,
      fontSize: 12,
    },
    total: {
      width: 300,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 225,
      },
    },
    subTotal: {
      paddingLeft: 10,
      fontSize: 12,
    },
  });

  const classes = useStyles();

  return (
    <>
      <div className={classes.priceBox}>
        <div className={classes.left}>
          <Text
            text={price}
            isDark
            align="center"
            className={{ lineHeight: 3 }}
          />
        </div>
        <div className={classes.right}>
          <Text
            text={`${quantity}点`}
            isDark
            align="center"
            className={{ lineHeight: 3 }}
          />
          {/* {subTotal ? (
            <Typography
              className={classes.number}
              onClick={() => switchSubTotal()}
            >
              {`${quantity} ▼`}
            </Typography>
          ) : (
            <Typography
              className={classes.number}
              onClick={() => switchSubTotal()}
            >
              {`${quantity} ▲`}
            </Typography>
          )} */}
        </div>
      </div>
    </>
  );
};

import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  label: string;
  detail: string;
};

export const ReserveItemInfo = (props: Props) => {
  const { label, detail } = props;
  const useStyles = makeStyles({
    itemInfo: {
      width: 300,
      display: "flex",
      gap: 4,
      justifyContent: "center",
      "@media screen and (max-width: 499px)": {
        width: 200,
      },
    },
    itemInfoLeft: {
      width: "50%",
    },
    itemInfoRight: {
      width: "50%",
    },
    label: {},
    rightLabel: {
      fontSize: 12,
      lineHeight: 3,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.itemInfo}>
      <div className={classes.itemInfoRight}>
        <Text
          text={label}
          isDark
          align="center"
          className={{ lineHeight: 3 }}
        />
      </div>
      <div className={classes.itemInfoLeft}>
        <Text
          text={detail}
          isDark
          align="center"
          className={{ lineHeight: 3 }}
        />
      </div>
    </div>
  );
};

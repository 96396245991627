import { makeStyles } from "@material-ui/core";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  bgc?: string;
  border?: string;
};

export const ReserveInfo = (props: Props) => {
  const useStyles = makeStyles({
    textBox: {
      border: "1px solid rgb(80, 80, 80)",
      margin: "16px 24px",
      padding: 15,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.textBox}>
      <Text text="【キャンセルポリシー】" isDark />
      <Text text="購入または予約完了後はキャンセルできません。" isDark />
      <Text
        text="カラー、サイズ、個数に間違いがないかご注意ください。"
        isDark
      />
    </div>
  );
};

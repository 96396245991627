import { makeStyles } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Text } from "../atoms/Text/Text";
import { Address } from "../../Types/userType";
import {
  generateDeliveryName,
  splitZipCode,
} from "../../features/helpers/deliveryHelper";
import { DeliveryUserInfo } from "../DeliveryReg/DeliveryUserInfo";

type Props = {
  address: Omit<Address, "uid">;
  addresses?: Omit<Address, "uid">[];
  setIsSelectAddresses?: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectMode?: boolean;
  setAddress?: React.Dispatch<
    React.SetStateAction<Omit<Address, "uid"> | undefined>
  >;
  isCurrent?: boolean;
};

export const SelectedAddress = (props: Props) => {
  const {
    address,
    addresses,
    setIsSelectAddresses,
    isSelectMode = false,
    setAddress,
    isCurrent = false,
  } = props;
  const useStyles = makeStyles({
    addressInfoArea: {
      padding: 10,
    },
    addressTitle: {
      height: 20,
      "& p": {
        fontSize: 14,
      },
    },
    addressArea: {
      display: "flex",
    },
    addressInfo: {
      flex: 7,
    },
    changeArea: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  return (
    <div key={address.aid}>
      <div className={classes.addressInfoArea}>
        <div className={classes.addressArea}>
          <div className={classes.addressInfo}>
            {isCurrent && (
              <Text
                isDark
                text="※現在選択されている住所"
                className={{
                  marginLeft: 20,
                  marginBottom: 8,
                }}
              />
            )}
            <DeliveryUserInfo
              label={generateDeliveryName(
                address.lastName,
                address.firstName,
                address.lastNameKana,
                address.firstNameKana
              )}
            />
            <DeliveryUserInfo label={`${splitZipCode(address.zipcode)}`} />
            <DeliveryUserInfo
              label={
                address.prefecture +
                address.city +
                address.town +
                address.address
              }
            />
            <DeliveryUserInfo label={address.buildingName} />
            <DeliveryUserInfo label={"TEL " + address.phoneNumber} />
          </div>
          {addresses && addresses.length > 1 && (
            <div className={classes.changeArea}>
              <GrayButton
                width={80}
                backgroundColor="rgba(0,0,0,0)"
                color="rgb(80, 80, 80)"
                variant="outlined"
                className={{
                  fontSize: 10,
                }}
                onClick={() =>
                  setIsSelectAddresses && setIsSelectAddresses(true)
                }
              >
                届け先を変更する
              </GrayButton>
            </div>
          )}
          {isSelectMode && (
            <div className={classes.changeArea}>
              <GrayButton
                width={80}
                backgroundColor="white"
                color="rgb(80, 80, 80)"
                variant="outlined"
                className={{
                  fontSize: 10,
                }}
                onClick={() => {
                  setAddress && setAddress(address);
                  setIsSelectAddresses && setIsSelectAddresses(false);
                }}
              >
                この住所に届ける
              </GrayButton>
            </div>
          )}
        </div>
      </div>
      <GrayBack height={1} isDark />
    </div>
  );
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login, logout } from "./features/userSlice";
import { analytics, auth } from "./firebase";
import { RouteProps } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { userDocument } from "./firebasePaths";
import { getDoc } from "firebase/firestore";
import { setUserId } from "firebase/analytics";

export const Auth = ({ children }: RouteProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const unSub = onAuthStateChanged(auth, async (authUser) => {
      // メール認証済チェック
      if (authUser) {
        const usersRef = userDocument(authUser.uid);
        setUserId(analytics, authUser.uid);
        try {
          const snapshot = await getDoc(usersRef);
          if (snapshot.exists()) {
            // ← データが存在するか確認
            const data = snapshot.data();
            dispatch(
              login({
                uid: authUser.uid,
                displayName: data.displayName
                  ? data.displayName
                  : data.username,
                username: data.username,
                role: data.role,
                isSignedIn: true,
                photoUrl: data.photoUrl,
                notice: data.notice,
                selfIntroduction: data.selfIntroduction,
                twitterAccount: data.twitterAccount,
                tiktokAccount: data.tiktokAccount,
                instagramAccount: data.instagramAccount,
                followCount: data.followCount,
                followerCount: data.followerCount,
                postCount: data.postCount,
                followInfo: data.followInfo,
                likeInfo: data.likeInfo,
                wantInfo: data.wantInfo,
                commentInfo: data.commentInfo,
                mentionInfo: data.mentionInfo,
              })
            );
          } else {
            // ユーザーデータが存在しない場合はログアウト
            dispatch(logout());
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch(logout());
        }
      } else {
        dispatch(logout());
      }
    });
    return () => {
      unSub();
    };
  }, []);

  return <>{children}</>;
};

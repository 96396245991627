import { makeStyles } from "@material-ui/core";
import { ReserveItemInfo } from "./ReserveItemInfo";
import { ReservePrice } from "./ReservePrice";
import { GrayBack } from "../../../components/MyPage/GrayBack";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  productData: {
    color: string;
    value: string;
  };
  productDetail: {
    ref: string;
    size: string;
    selectedQuantity: number;
  };
  image: string;
  projectName: string;
  retailPrice: number;
  itemName: string;
};

export const ReserveProduct = (props: Props) => {
  const {
    productDetail,
    productData,
    image,
    projectName,
    retailPrice,
    itemName,
  } = props;
  const useStyles = makeStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "0 24px",
    },
    productDetail: {
      display: "flex",
      justifyContent: "center",
      height: 166,
    },
    left: {
      width: 180,
      "@media screen and (max-width: 499px)": {
        width: 135,
      },
    },
    right: {
      width: 320,
      "@media screen and (max-width: 499px)": {
        width: 180,
      },
    },
    imageArea: {
      margin: "0 auto",
      width: 120,
    },
    image: {
      width: 120,
      height: 150,
      objectFit: "cover",
    },
    shipText: {
      marginBottom: 10,
    },
    projectNameSignboard: {
      fontWeight: "bold",
      marginRight: 16,
    },
    projectName: {
      marginBottom: 16,
    },
    totalPriceArea: {
      marginLeft: 30,
    },
    headerTextArea: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 8px",
    },
  });
  const classes = useStyles();
  const total = Number(retailPrice) * productDetail.selectedQuantity;
  const price = Number(total).toLocaleString();
  return (
    <div className={classes.container}>
      <div className={classes.headerTextArea}>
        <Text
          text="プロジェクト名"
          isDark
          isBold
          className={{ marginRight: 8 }}
        />
        <Text text={projectName} isDark />
      </div>
      <div className={classes.productDetail}>
        <div className={classes.left}>
          <div className={classes.imageArea}>
            <img src={image} className={classes.image} alt="productImage" />
          </div>
        </div>
        <div className={classes.right}>
          <ReserveItemInfo label="アイテム名" detail={itemName} />
          <ReserveItemInfo label="サイズ" detail={productDetail.size} />
          <ReserveItemInfo label="カラー" detail={productData.color} />
          <ReservePrice
            price={`¥${price}`}
            quantity={productDetail.selectedQuantity}
          />
        </div>
      </div>
      <GrayBack isDark height={1} />
    </div>
  );
};

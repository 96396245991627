import { useState } from "react";
import { GrayBack } from "../MyPage/GrayBack";
import { makeStyles, Switch, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { createAddress } from "../../features/addressOperation";
import { toast } from "react-toastify";
import { DeliveryInput } from "./DeliveryInput";
import { Zipcode } from "./Zipcode";
import { PrefectureArray } from "../../features/prefectures";
import { Prefecture } from "../../Types/deliveryType";
import { CityInput } from "./CityInput";
import { Text } from "../atoms/Text/Text";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { SalesProject } from "../../Types/productType";
import { useHistory } from "react-router-dom";
import { Center } from "../atoms/Layout/Center";
import { DeliveryFormValue } from "../../Types/userType";
import { Loading } from "../atoms/Loading/Loading";

type Props = {
  salesProject?: SalesProject;
  setCreateCounter?: React.Dispatch<React.SetStateAction<number>>;
  isAddressRegistrationPage?: boolean;
};

export const DeliveryAddressForm = (props: Props) => {
  const {
    salesProject,
    setCreateCounter,
    isAddressRegistrationPage = false,
  } = props;
  const useStyles = makeStyles({
    prefecturesContainer: {
      display: "flex",
    },
    prefecturesLeft: {
      flex: 6,
      "& p": {
        fontSize: 13,
        margin: "13px 0 8px 5px",
      },
    },
    prefecturesRight: {
      flex: 4,
    },
    labelRed: {
      color: "#FF6666",
      fontSize: 10,
    },
    selectBox: {
      width: 200,
      height: 45,
      fontSize: 16,
      border: "none",
      borderBottom: "1px solid rgb(151,151,151)",
      "&:disabled": {
        color: "black",
      },
    },
    inputArea: {
      margin: "8px 0 8px 4px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    nameInputArea: {
      display: "flex",
      gap: 8,
      justifyContent: "center",
    },
    buttonArea: {
      height: "100%",
      marginBottom: 16,
    },
  });
  const classes = useStyles();
  const [mainAddress, setMainAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const methods = useForm<DeliveryFormValue>({
    mode: "onChange", // リアルタイムバリデーション用
    reValidateMode: "onSubmit", // 送信時の再バリデーション用
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = methods;

  // 数字のみの正規表現変数
  const numberRegExp = /^[0０][\d０-９]{9,10}$/;

  // カタカナの正規表現
  const katakana = /^[ァ-ヶー\s]+$/; // 全角スペースを許可する場合

  const toHalfWidth = (str: string) => {
    return str.replace(/[０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
  };
  const switchCheck = () => {
    setMainAddress(!mainAddress);
  };

  const switchText = () => {
    if (mainAddress) return "ON状態";
    return "OFF状態";
  };

  const onSubmit = async (data: DeliveryFormValue) => {
    setIsLoading(true);
    try {
      const sendData = {
        ...data,
        mainAddress,
      };

      await createAddress(sendData);

      toast.success("住所を保存しました。");
      reset();
      if (setCreateCounter)
        setCreateCounter((prev) => {
          return prev + 1;
        });

      if (isAddressRegistrationPage) push("/mypage/delivery-list");
    } catch (error) {
      console.error(error);
      toast.error("住所の保存に失敗しました。\n再度お試しください。");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading text="登録中" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <GrayBack
          height={20}
          label="氏名を入力してください"
          fontSize="12px"
          color="gray"
          backGroundColor="white"
        />
        <div className={classes.nameInputArea}>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: "　入力必須です",
            }}
            render={({ field }) => (
              <DeliveryInput
                id="inputLastName"
                infoLabel=" 姓"
                labelRed="　※"
                place=" 例) 渋谷"
                autoComplete="family-name"
                field={field}
                error={errors.lastName}
              />
            )}
          />
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "　入力必須です",
            }}
            render={({ field }) => (
              <DeliveryInput
                id="inputFirstName"
                infoLabel=" 名"
                labelRed="　※"
                place=" 例) 渋子"
                autoComplete="given-name"
                field={field}
                error={errors.firstName}
              />
            )}
          />
        </div>
        <div className={classes.nameInputArea}>
          <Controller
            name="lastNameKana"
            control={control}
            rules={{
              pattern: {
                value: katakana,
                message: "　カタカナで入力してください",
              },
            }}
            render={({ field }) => (
              <DeliveryInput
                id="inputLastNameKana"
                infoLabel=" セイ"
                place=" 例) シブヤ"
                field={field}
                error={errors.lastNameKana}
              />
            )}
          />
          <Controller
            name="firstNameKana"
            control={control}
            rules={{
              pattern: {
                value: katakana,
                message: "　カタカナで入力してください",
              },
            }}
            render={({ field }) => (
              <DeliveryInput
                id="inputFirstNameKana"
                infoLabel=" メイ"
                place=" 例) シブコ"
                field={field}
                error={errors.firstNameKana}
              />
            )}
          />
        </div>
        <GrayBack
          height={20}
          label="郵便番号で入力してください"
          color="gray"
          fontSize="12px"
          backGroundColor="white"
        />
        <Controller
          name="zipcode"
          control={control}
          rules={{
            required: "　入力必須です",
            minLength: {
              value: 7,
              message: "　7文字で入力してください",
            },
            maxLength: {
              value: 7,
              message: "　7文字で入力してください",
            },
          }}
          render={({ field }) => (
            <Zipcode
              id="inputZipcode"
              infoLabel="郵便番号（ハイフンなし）"
              autoComplete="postal-code"
              labelRed="※"
              place=" 例) 1500001"
              field={{
                ...field,
                onChange: (e) => field.onChange(toHalfWidth(e.target.value)),
              }}
              error={errors.zipcode}
              setValue={setValue}
            />
          )}
        />
        <GrayBack
          height={20}
          label="※住所は自動で入力されるため、入力できません"
          color="gray"
          fontSize="12px"
          backGroundColor="white"
        />
        <div className={classes.prefecturesContainer}>
          <div className={classes.prefecturesLeft}>
            <Typography>
              都道府県
              <span className={classes.labelRed}>　※自動入力</span>
            </Typography>
          </div>
          <div className={classes.prefecturesRight}>
            <select
              className={classes.selectBox}
              disabled
              id=""
              {...register("prefecture", { required: "　必須です。" })}
            >
              {PrefectureArray.map((pref: Prefecture, index: number) => (
                <option key={index} value={pref.value}>
                  {pref.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Controller
          name="city"
          control={control}
          rules={{
            required: "　入力必須です",
          }}
          render={({ field }) => (
            <CityInput
              id="inputCity"
              infoLabel=" 市区町村"
              labelRed="　※自動入力"
              place=" 例 渋谷区渋谷"
              field={field}
              disabled
            />
          )}
        />
        <Controller
          name="town"
          control={control}
          rules={{
            required: "　入力必須です",
          }}
          render={({ field }) => (
            <CityInput
              id="inputTown"
              infoLabel=" 町名"
              labelRed="　※自動入力"
              place=" 例 渋谷"
              field={field}
              disabled
            />
          )}
        />
        <GrayBack
          height={20}
          label="番地、建物名、部屋番号を入力してください"
          color="gray"
          fontSize="12px"
          backGroundColor="white"
        />
        <Controller
          name="address"
          control={control}
          rules={{
            required: "　入力必須です",
          }}
          render={({ field }) => (
            <DeliveryInput
              id="inputAddress"
              infoLabel=" 番地"
              labelRed="　※"
              place=" 例) 1-23-20"
              field={{
                ...field,
                onChange: (e) => field.onChange(toHalfWidth(e.target.value)),
              }}
              error={errors.address}
            />
          )}
        />
        <Controller
          name="buildingName"
          control={control}
          render={({ field }) => (
            <DeliveryInput
              infoLabel=" 建物名・部屋番号"
              place=" 例) 松本ビル601"
              field={field}
            />
          )}
        />
        <GrayBack
          height={20}
          label="電話番号を入力してください"
          color="gray"
          fontSize="12px"
          backGroundColor="white"
        />
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: "　入力必須です",
            pattern: {
              value: numberRegExp,
              message: "　入力に誤りがあります",
            },
            validate: (value) => {
              const halfWidthValue = toHalfWidth(value);
              return (
                (halfWidthValue.length >= 10 && halfWidthValue.length <= 11) ||
                "　桁数が正しくありません"
              );
            },
          }}
          render={({ field }) => (
            <DeliveryInput
              id="inputPhoneNumber"
              inputMode="numeric"
              infoLabel=" 電話番号（ハイフンなし）"
              labelRed="※"
              place=" 例) 09011112222"
              autoComplete="tel"
              type="tel"
              field={{
                ...field,
                onChange: (e) => field.onChange(toHalfWidth(e.target.value)),
              }}
              error={errors.phoneNumber}
            />
          )}
        />
        <div className={classes.inputArea}>
          <Text
            text={`メインの住所に設定する ${switchText()}　 ※初めての登録は自動でON`}
            className={{
              TextAlign: "center",
            }}
          />
          <Switch
            onChange={() => {
              switchCheck();
            }}
            color="default"
            value={mainAddress}
          />
        </div>
        <div className={classes.buttonArea}>
          <Center>
            <GrayButton
              type="submit"
              width={250}
              className={{
                fontSize: 12,
              }}
            >
              登録する
            </GrayButton>
          </Center>
          {salesProject && (
            <Center
              className={{
                marginTop: 20,
              }}
            >
              <GrayButton
                width={250}
                onClick={() => push("/product/reserve", salesProject)}
              >
                購入画面に戻る
              </GrayButton>
            </Center>
          )}
        </div>
      </form>
    </>
  );
};

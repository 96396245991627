import { makeStyles } from "@material-ui/core";
import { ReservePriceDetail } from "./ReservePriceDetail";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  paymentAmount: number;
  discountedPrice: number;
  discountRate: number;
  isNotExist?: boolean;
  quantity: number;
};

export const ReservePriceTotal = (props: Props) => {
  const { paymentAmount, discountedPrice, discountRate, isNotExist, quantity } =
    props;
  const useStyles = makeStyles({
    priceArea: {
      padding: "0 16px",
    },
    totalPrice: {
      width: 320,
      margin: "8px auto 0",
      borderBottom: "1px solid rgb(200,200,200)",
    },
    total: {
      display: "flex",
      width: 320,
      margin: "8px auto 0",
    },
    left: {
      flex: 1,
      paddingLeft: 8,
    },
    right: {
      flex: 1,
      paddingRight: 8,
    },
  });
  const classes = useStyles();
  const totalAmount = paymentAmount * quantity;

  const accountingPriceCalculation = Math.floor(totalAmount) + 0; // キャンペーン中
  const accountingPrice = accountingPriceCalculation.toLocaleString();
  return (
    <div className={classes.priceArea}>
      <div className={classes.totalPrice}>
        <ReservePriceDetail
          label="商品合計（税込）"
          price={`¥ ${Math.floor(totalAmount).toLocaleString()}`}
        />
        <ReservePriceDetail label="送料（無料キャンペーン）" price="¥ 0" />
        {/* <ReservePriceDetail label="送料" price="¥600" /> キャンペーン中 */}
        {isNotExist === false && (
          <>
            <ReservePriceDetail
              label="割引額"
              price={`¥ -${discountedPrice.toLocaleString()}`}
            />
            <ReservePriceDetail label="割引率" price={`${discountRate}%`} />
          </>
        )}
      </div>
      <div className={classes.total}>
        <div className={classes.left}>
          <Text text="合計（税込）" isDark />
        </div>
        <div className={classes.right}>
          <Text text={`¥${accountingPrice}`} isDark align="right" />
        </div>
      </div>
    </div>
  );
};

import { makeStyles } from "@material-ui/core";
import {
  SelectedProduct,
  PreOrderProduct as PreOrderProductType,
} from "../../Types/productType";
import { Text } from "../atoms/Text/Text";
import { PreOrderSizeAndColorTable } from "./PreOrderSizeAndColorTable";
import { PreOrderTitle } from "./PreOrderTitle";
import { GrayBack } from "../MyPage/GrayBack";

type Props = {
  selectedProduct: SelectedProduct;
};

export const PreOrderProduct = (props: Props) => {
  const { selectedProduct } = props;
  const useStyles = makeStyles({
    preOrderProductContainer: {
      marginTop: 8,
      display: "flex",
      gap: 8,
      padding: "8px 0",
    },
    imageArea: {
      width: 180,
      height: 225,
      overflow: "hidden", // はみ出た部分を隠す
      "@media screen and (max-width: 499px)": {
        width: 130,
        height: 187.5,
      },
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover", // アスペクト比を維持しながら、コンテナを埋める
    },
    selectedProductInfo: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
      height: "100%", // 親要素の高さいっぱいに広げる
    },
    productInfoItem: {
      flexGrow: 1, // 各アイテムを均等に成長させる
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    totalPriceArea: {
      flexGrow: 1, // 各アイテムを均等に成長させる
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      justifyContent: "center",
      height: 40,
    },
  });
  const classes = useStyles();

  function calculateTotalQuantity(variants: PreOrderProductType[]) {
    return variants.reduce((total, variant) => {
      return (
        total +
        variant.detail.reduce((variantTotal, detail) => {
          return variantTotal + detail.selectedQuantity;
        }, 0)
      );
    }, 0);
  }

  const selectedProductCount = calculateTotalQuantity(
    selectedProduct.reserveProducts
  );
  const currentTotalPrice = selectedProduct.retailPrice * selectedProductCount;

  return (
    <div>
      <GrayBack isDark height={1} />
      <PreOrderTitle title="選択をした商品情報" />
      <GrayBack isDark height={1} />
      <div className={classes.preOrderProductContainer}>
        <div className={classes.imageArea}>
          <img
            src={selectedProduct.image}
            alt="productImage"
            className={classes.image}
          />
        </div>
        <div className={classes.selectedProductInfo}>
          <div className={classes.productInfoItem}>
            <Text isDark text="■プロジェクト名" />
            <Text isDark text={`${selectedProduct.projectName}`} />
          </div>
          <div className={classes.productInfoItem}>
            <Text isDark text="■クリエイター" />
            <Text isDark text={selectedProduct.creatorName} />
          </div>
          <div className={classes.productInfoItem}>
            <Text isDark text="■カテゴリー" />
            <Text isDark text={selectedProduct.itemName} />
          </div>
          <div className={classes.productInfoItem}>
            <Text isDark text="■選択中のカラーとサイズ" />
          </div>
          <div className={classes.productInfoItem}>
            <PreOrderSizeAndColorTable
              preReserveProducts={selectedProduct.reserveProducts}
            />
          </div>
          <div className={classes.totalPriceArea}>
            <Text
              isDark
              text={`¥${currentTotalPrice.toLocaleString()} 計${selectedProductCount}点`}
            />
          </div>
        </div>
      </div>
      <Text
        isDark
        text="※クーポンをお持ちの方は次のページに入力があります"
        align="center"
      />
    </div>
  );
};

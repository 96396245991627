import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import cuid from "cuid";
import { LatestColorSalesInfo, SalesProject } from "../../../Types/productType";
import { LoginInfo } from "../../../components/feature/LoginInfo/LoginInfo";
import { useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { Center } from "../../../components/atoms/Layout/Center";
import { auth } from "../../../firebase";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  project: SalesProject;
  salesDetailData: LatestColorSalesInfo[];
  calcPrice: number;
};

export const ProductBuyButton = (props: Props) => {
  const { project, salesDetailData, calcPrice } = props;
  const [show, setShow] = useState(false);
  const { push, location } = useHistory();
  const { pathname } = location;
  const { sale } = project.salesStatus;
  const isLogin = auth.currentUser;
  const useStyles = makeStyles({
    btnArea: {
      margin: "16px 0",
    },
    box: {
      margin: "0 auto",
    },
    btn: {
      display: "block",
      margin: "0 auto",
      width: 150,
      color: "white",
      fontSize: 12,
      backgroundColor: "rgb(177,177,177)",
      borderRadius: 0,
    },
    unLoginBtnArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 8,
      marginBottom: 12,
    },
  });
  const classes = useStyles();
  const user = useSelector(selectUser);
  const reserveSubmit = (choiceProduct: LatestColorSalesInfo[]) => {
    const {
      salesStatus,
      projectRef,
      toBeShipDate,
      projectName,
      images,
      category,
      memer,
    } = project;

    const image = images[0];
    const itemName = category.label;
    const reserveProducts = choiceProduct
      .filter((product) => product.nowQuantity > 0)
      .map(({ color, value, detail }) => ({
        color,
        value,
        detail: detail
          .filter(({ selectedQuantity }) => selectedQuantity > 0)
          .map(({ size, ref, selectedQuantity }) => ({
            size,
            ref,
            selectedQuantity,
          })),
      }));

    const selectedQuantityTotal = reserveProducts.reduce((acc, curr) => {
      return (
        acc +
        curr.detail.reduce((acc2, curr2) => {
          return acc2 + curr2.selectedQuantity;
        }, 0)
      );
    }, 0);

    const setStore = {
      projectRef,
      toBeShipDate,
      salesStatus,
      projectName,
      retailPrice: calcPrice,
      image,
      reserveProducts,
      selectedQuantityTotal,
      itemName,
      creatorName: memer.label,
    };
    const reserveId = cuid();
    // dispatch(setReserveProductStore(setStore));
    const projectInCustomerIdData = {
      ...project,
      reserveId,
      prevLocation: location.pathname,
      retailPrice: calcPrice,
    };
    // push("/product/reserve", projectInCustomerIdData);
    push("/product/reserve/setup", {
      projectData: projectInCustomerIdData,
      selectedProduct: setStore,
    });
  };

  const isActive = () => {
    const check = salesDetailData.filter((data) => data.nowQuantity > 0);
    return check.length === 0;
  };

  const reserveSend = () => {
    if (user.isSignedIn) {
      reserveSubmit(salesDetailData);
      return;
    }
    setShow(true);
  };
  return (
    <div>
      {isLogin ? (
        <div className={classes.btnArea}>
          <Center>
            <GrayButton
              width={300}
              disabled={isActive()}
              onClick={() => reserveSend()}
              className={{
                fontSize: 12,
              }}
            >
              {sale ? "購入する" : "予約注文する"}
            </GrayButton>
          </Center>
          <LoginInfo
            isShow={show}
            setState={setShow}
            targetUrl={location.pathname}
          />
        </div>
      ) : (
        <div className={classes.unLoginBtnArea}>
          <Text
            text="アカウント登録済みの方は、ログインをしてください"
            align="center"
          />
          <GrayButton
            width={300}
            onClick={() => push(`/signin?from=${pathname}`)}
            className={{
              fontSize: 12,
            }}
          >
            ログイン
          </GrayButton>
          <Text
            text="アカウントお持ちでない方は、新規登録が必要です"
            align="center"
          />
          <GrayButton
            width={300}
            onClick={() => push(`/signup?from=${pathname}`)}
            className={{
              fontSize: 12,
            }}
          >
            新規登録
          </GrayButton>
        </div>
      )}
    </div>
  );
};

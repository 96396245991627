import { auth } from "../firebase/index";
import { push } from "connected-react-router";
import { AppThunk } from "../app/store";
import { addressDocument, addressesCollection } from "../firebasePaths";
import {
  deleteDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { logError } from "../lib/logError";
import { Address, DeliveryFormValue } from "../Types/userType";

const timestamp = serverTimestamp();

export const updateMainAddress = (
  address: Omit<Address, "uid">[]
): AppThunk => {
  return async (): Promise<void> => {
    const uid = auth.currentUser!.uid;
    address.forEach((addr) => {
      const addressRef = addressDocument(uid, addr.aid);

      setDoc(
        addressRef,
        { mainAddress: addr.mainAddress },
        { merge: true }
      ).catch((e) => {
        logError(e);
      });
    });
  };
};

export const createAddress = async (address: DeliveryFormValue) => {
  const { uid } = auth.currentUser!;
  const addressesRef = addressesCollection(uid);
  const addressesResponse = await getDocs(addressesRef);
  const addresses = addressesResponse.docs.map((doc) => {
    console.log(doc.data());
    return doc.data() as Address;
  });

  // 現在登録のアドレスがなければ強制でメイン住所とするルート
  if (addresses.length === 0) {
    const { mainAddress, ...omit } = address;
    await sendAddress({
      ...omit,
      mainAddress: true,
    });

    return;
  }
  // 今回メイン住所にした場合、かつすでにメイン住所が存在する場合、既存のメイン住所をサブ住所に変更するルート
  if (address.mainAddress) {
    const addressPromise = addresses.map(async (add) => {
      const { aid, mainAddress, uid: currentUid } = add;
      if (!mainAddress) return;

      await updateDoc(addressDocument(currentUid ?? "", aid), {
        mainAddress: false,
      });
    });
    await Promise.all(addressPromise);
  }
  await sendAddress(address);
};

export const sendAddress = async (address: DeliveryFormValue) => {
  const uid = auth.currentUser!.uid;

  const addressesRef = addressDocument(uid);

  const addressData = {
    aid: addressesRef.id,
    ...address,
    uid,
    createdAt: timestamp,
  };

  await setDoc(addressesRef, addressData);
};

export const isMainAddressCheck = async (address: Omit<Address, "uid">) => {
  const uid = auth.currentUser!.uid;
  const addressesRef = addressesCollection(uid);
  if (address.mainAddress) {
    getDocs(addressesRef)
      .then((doc) => {
        doc.forEach(async (data) => {
          const addressData = data.data();
          if (address.aid !== data.id) {
            const allFalse: Pick<Address, "mainAddress"> = {
              mainAddress: false,
            };
            await setDoc(addressDocument(uid, addressData.aid), allFalse, {
              merge: true,
            });
          }
        });
      })
      .then(async () => {
        await updateAddress(address);
        return;
      });
  }
  await updateAddress(address);
};

const updateAddress = async (address: Omit<Address, "uid">) => {
  const uid = auth.currentUser!.uid;
  const addressRef = addressDocument(uid, address.aid);
  const updateAddressData = {
    lastName: address.lastName,
    firstName: address.firstName,
    lastNameKana: address.lastNameKana,
    firstNameKana: address.firstNameKana,
    zipcode: address.zipcode,
    prefecture: address.prefecture,
    city: address.city,
    town: address.town,
    address: address.address,
    buildingName: address.buildingName,
    phoneNumber: address.phoneNumber,
    mainAddress: address.mainAddress,
  };
  await setDoc(addressRef, updateAddressData, { merge: true });
};

export const fetchAddress = async (
  setAddresses: React.Dispatch<React.SetStateAction<Omit<Address, "uid">[]>>,
  setAddress?: React.Dispatch<
    React.SetStateAction<Omit<Address, "uid"> | undefined>
  >,
  setIsCreate?: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAddressLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      const addressesRef = addressesCollection(user.uid);
      const addressResponse = await getDocs(addressesRef);

      const addresses = addressResponse.docs.map((addDoc, index) => {
        const address = addDoc.data();

        const newBody: Address = {
          zipcode: address.zipcode,
          prefecture: address.prefecture,
          city: address.city,
          town: address.town,
          address: address.address,
          buildingName: address.buildingName,
          firstName: address.firstName,
          lastName: address.lastName,
          firstNameKana: address.firstNameKana,
          lastNameKana: address.lastNameKana,
          phoneNumber: address.phoneNumber,
          mainAddress: address.mainAddress!,
          aid: address.aid,
          addressIdx: String(index + 1),
          uid: address.uid,
        };
        if (setAddress && newBody.mainAddress) setAddress(newBody);
        return newBody;
      });
      setAddresses(addresses);
      if (setIsCreate) {
        if (addresses.length === 0) {
          setIsCreate(true);
        } else {
          setIsCreate(false);
        }
      }
      if (setIsAddressLoading) setIsAddressLoading(false);
    }
    unsubscribe();
  });
};

export const addressLengthCheck = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const addressesRef = addressesCollection(uid);
    const addressLength = (await getDocs(addressesRef)).size;
    if (addressLength >= 5) {
      alert(`住所登録は5つまでです。`);
    } else {
      dispatch(push("/address-registration/" + uid));
    }
  };
};

export const deleteAddress = (aid: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const addressRef = addressDocument(uid, aid);
    await deleteDoc(addressRef);
  };
};

export const fetchDeliveryAddress = (
  setAddress: React.Dispatch<React.SetStateAction<Address>>
) => {
  let unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      const addressesRef = addressesCollection(user.uid);
      const addressResponse = await getDocs(addressesRef);

      const addresses = addressResponse.docs.map((addDoc) => {
        const data = addDoc.data() as Address;
        return data;
      });

      const mainAddress = addresses.find((address) => address.mainAddress);
      if (mainAddress) {
        setAddress(mainAddress);
        return;
      }
      if (!Array.isArray(addresses)) setAddress(addresses[0]);
    }
    unsubscribe();
  });
};

export const fetchAddressChange = (
  uid: string,
  setChangeAddress: Function
): AppThunk => {
  return async (): Promise<void> => {
    const addressesRef = addressesCollection(uid);
    const addressBox: any[] = [];
    getDocs(addressesRef)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          addressBox.push(data);
        });
      })
      .then(() => {
        setChangeAddress(addressBox);
      });
  };
};

import { useHistory } from "react-router";
import { makeStyles, Typography } from "@material-ui/core";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { Text } from "../../components/atoms/Text/Text";

export const SignUpConfirm = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    bigText: {
      marginBottom: 30,
    },
    text: {
      padding: "0 10px",
    },
    buttonArea: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
    },
  });
  const { push } = useHistory();
  const classes = useStyles();
  return (
    <div>
      <BrowserBackHeader label="メール送信済み" browserBack={history} />
      <Text
        text="登録ありがとうございます！"
        align="center"
        isDark
        fontSize={16}
        className={{
          margin: "48px 0",
        }}
      />
      <Text
        text={`入力されたメールアドレスへ認証用リンクを送信しました。\nメールの受信を確認いただき、メール文のリンクにアクセスすることで、登録完了となります。`}
        align="center"
        isDark
        fontSize={14}
      />

      <Typography className={classes.text}></Typography>
      <Typography className={classes.text}></Typography>
      <div className={classes.buttonArea}>
        <GrayButton onClick={() => push("/signin")} width="80%" isDefault>
          ログイン画面へ戻る
        </GrayButton>
      </div>
    </div>
  );
};

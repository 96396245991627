import { Box, CircularProgress, makeStyles } from "@material-ui/core";

type Props = {
  height: number;
  width?: number | string;
};

export const LoadingSkelton = (props: Props) => {
  const { width = "100%", height } = props;
  const useStyles = makeStyles({
    skeletonContainer: {
      position: "relative",
      width,
      height,
    },
    circularProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      animation: "none",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.skeletonContainer} width={width} height={height}>
      <CircularProgress size={30} className={classes.circularProgress} />
    </Box>
  );
};

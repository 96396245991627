import React from "react";
import { makeStyles } from "@material-ui/core";
import { Text } from "../atoms/Text/Text";

type Props = {
  title: string;
  marginBottom?: number;
};

export const PreOrderTitle = (props: Props) => {
  const { title, marginBottom } = props;
  const useStyles = makeStyles({
    titleArea: {
      width: "100%",
      margin: "0 auto",
      padding: "4px 0",
      backgroundColor: "rgb(245,245,245)",
      marginBottom: marginBottom ? marginBottom : 0,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.titleArea}>
      <Text align="center" text={title} isDark />
    </div>
  );
};

import { Address } from "../../Types/userType";
import { makeStyles } from "@material-ui/core";
import { SelectedAddress } from "./SelectedAddress";
import { GrayBack } from "../MyPage/GrayBack";
type Props = {
  addresses: Omit<Address, "uid">[];
  selectedAddress?: Omit<Address, "uid">;
  setAddress?: React.Dispatch<
    React.SetStateAction<Omit<Address, "uid"> | undefined>
  >;
  setIsSelectAddresses?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddressList = (props: Props) => {
  const { addresses, selectedAddress, setAddress, setIsSelectAddresses } =
    props;
  const useStyles = makeStyles({
    background: {
      backgroundColor: "white",
      width: 350,
      padding: 16,
      height: "100%",
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <GrayBack height={1} isDark />
      {addresses.map((address) => (
        <div
          style={{
            backgroundColor:
              selectedAddress?.aid === address.aid
                ? "rgba(188, 226, 232, 0.5)"
                : "white",
          }}
        >
          <SelectedAddress
            address={address}
            isSelectMode
            setAddress={setAddress}
            isCurrent={selectedAddress?.aid === address.aid}
            setIsSelectAddresses={setIsSelectAddresses}
          />
        </div>
      ))}
    </div>
  );
};

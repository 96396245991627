import { useEffect, useState } from "react";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import CreditNumberTest from "./CreditNumberTest";
import { History as BrowserHistory } from "history";
import { SpaceBox } from "../UIKit/SpaceBox";
import { creditsCollection } from "../../firebasePaths";
import { getDocs } from "firebase/firestore";
import { CreditForm } from "./CreditForm";

type Props = {
  history: BrowserHistory;
  uid: string;
  salesProject?: unknown;
};

export const StripeCreditCreate = (props: Props) => {
  const { history, uid, salesProject } = props;
  const [customerID, setCustomerID] = useState("");
  const customerIDRef = creditsCollection(uid);

  useEffect(() => {
    let isMounted = true;
    const getCustomerId = async () => {
      await getDocs(customerIDRef).then((snapshot) => {
        // Docがある場合しかイテレートが回らない
        snapshot.forEach((cusData) => {
          const data = cusData.data();
          if (isMounted) {
            setCustomerID(data.stripeCustomerID);
          }
        });
      });
    };

    getCustomerId();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <BrowserBackHeader label="クレジット登録" browserBack={history} />
      <CreditForm
        uid={uid}
        customerId={customerID}
        setCustomerId={setCustomerID}
        salesProject={salesProject}
        isCardRegistrationPage
      />
      <SpaceBox height={50} />
      {process.env.REACT_APP_ENV === "development" && <CreditNumberTest />}
    </div>
  );
};

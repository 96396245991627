import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectUser } from "../../../features/userSlice";
import { logError } from "../../../lib/logError";
import { stripeError } from "../../../lib/errorText";
import { toast } from "react-toastify";
import { deliveryOrderNumber } from "../../../features/helpers/deliveryHelper";
import { Project } from "../../../Types/projectType";
import { SlackSendData } from "../../../Types/slackType";
import {
  slackNotice,
  sendReserveProduct,
  // sendBuyProduct,
  // stripeRetrievePaymentIntent,
  // stripeIntentPaymentMethod,
} from "../../../function/cloudFunctions";
import { getDocumentRef } from "../../../firebasePaths";
import { collection, getDoc, getDocs } from "firebase/firestore";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { Center } from "../../../components/atoms/Layout/Center";
import { Credit } from "../../../Types/paymentType";
import { useDebounce } from "../../../hooks/useDebounce";
import { analyticsCustomLogEvent } from "../../../firebase";
import { Label, Products, SelectedProduct } from "../../../Types/productType";
import { Address } from "../../../Types/userType";

type Props = {
  deliveryAddress: Omit<Address, "uid">;
  useCredit: Credit;
  customerID?: string;
  setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reserveId?: string;
  isNotExist?: boolean;
  paymentAmount: number;
  discountedPrice: number;
  discountRate: number;
  prevLocation?: string;
  creatorInfo: Label;
  selectedProduct: SelectedProduct;
  couponCode: string;
};
export const BuySubmitButton = (props: Props) => {
  const {
    deliveryAddress,
    useCredit,
    customerID,
    setSubmitLoading,
    reserveId,
    paymentAmount,
    discountRate,
    prevLocation,
    creatorInfo,
    selectedProduct,
    couponCode,
  } = props;

  const { underReservation, inProduction, sale } = selectedProduct.salesStatus;

  const user = useSelector(selectUser);

  const { push } = useHistory();

  const debounce = useDebounce(200);

  // 予約
  const reserveSubmit = async () => {
    setSubmitLoading(true);

    const { uid } = user;

    // CFから伝票番号発行
    let orderNumber: string;
    try {
      orderNumber = await deliveryOrderNumber();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      toast.error(
        "購入処理に失敗しました。\nもう一度最初からやり直してください。"
      );
      return;
    }

    const address = `${deliveryAddress?.prefecture}${deliveryAddress?.city}${
      deliveryAddress?.town
    }${deliveryAddress?.address}${
      deliveryAddress?.buildingName ? ` ${deliveryAddress?.buildingName}` : ""
    }`;
    const zipcode = deliveryAddress?.zipcode ?? "";
    const phoneNumber = deliveryAddress?.phoneNumber ?? "";
    const name = `${deliveryAddress?.lastName ?? ""} ${
      deliveryAddress?.firstName ?? ""
    }`;

    const productData = JSON.stringify(selectedProduct.reserveProducts);
    let pid;
    try {
      const projectRef = getDocumentRef(selectedProduct.projectRef);
      const productRef = collection(projectRef, "products");
      const reserveCheck: boolean[] = [];
      let productNumber = "";

      const reserveStatusLimitCheck = (
        targetProject: Project,
        products: Products[]
      ) => {
        const limitCheckProduct = targetProject.colors?.map(
          ({ value, limit, label }) => {
            const reserveSum = products
              .filter((item) => item.color === label)
              .reduce((acc, { reserve }) => acc + reserve, 0);
            return { value, limit, label, reserve: reserveSum };
          }
        );

        selectedProduct.reserveProducts.forEach((item1) => {
          const matchingItem2 = limitCheckProduct?.find(
            (item2) => item2.label === item1.color
          );
          if (matchingItem2) {
            const totalReserved = item1.detail.reduce(
              (sum, detailItem) => sum + detailItem.selectedQuantity,
              0
            );
            const availableLimit = matchingItem2.limit - matchingItem2.reserve;

            if (totalReserved > availableLimit) {
              toast.error(
                `予約制限を超えたため、${item1.color}の商品の予約はできません。`
              );
              reserveCheck.push(false);

              return;
            }
            reserveCheck.push(true);
          }
        });
      };

      const inProductionLimitCheck = (products: Products[]) => {
        const limitCheckProduct = products.map(
          ({ reserveLimit, color, reserve, size }) => {
            return {
              reserveLimit: reserveLimit ?? 0,
              reserve,
              label: color,
              size,
            };
          }
        );

        selectedProduct.reserveProducts.forEach((reserveProduct) => {
          reserveProduct.detail.forEach((detail) => {
            const matchingItem = limitCheckProduct?.find(
              (product) =>
                product.label === reserveProduct.color &&
                product.size === detail.size
            );
            if (matchingItem) {
              const { selectedQuantity } = detail;
              const availableLimit =
                matchingItem.reserveLimit - matchingItem.reserve;
              if (selectedQuantity > availableLimit) {
                toast.error(
                  `予約制限を超えたため、${reserveProduct.color}の${detail.size}の商品の予約はできません。`
                );
                reserveCheck.push(false);
              }
            }
          });

          // const matchingItem2 = limitCheckProduct?.find(
          //   (item2) => item2.label === item1.color
          // );
          // if (matchingItem2) {
          //   const totalReserved = item1.detail.reduce(
          //     (sum, detailItem) => sum + detailItem.selectedQuantity,
          //     0
          //   );
          //   const availableLimit =
          //     matchingItem2.reserveLimit - matchingItem2.reserve;

          //   if (totalReserved > availableLimit) {
          //     toast.error(
          //       `予約制限を超えたため、${item1.color}の商品の予約はできません。`
          //     );
          //     reserveCheck.push(false);

          //     return;
          //   }
          //   reserveCheck.push(true);
          // }
        });
      };

      const fetchProductsLimitCheck = async () => {
        const projectData = await getDoc(projectRef);
        const productSnapshot = await getDocs(productRef);
        const project = projectData.data() as Project;
        productNumber = project.productNumber;
        const products: any[] = [];
        productSnapshot.forEach((doc) => {
          const product = doc.data();
          products.push(product);
        });

        if (inProduction || sale) {
          inProductionLimitCheck(products);
        }
        if (underReservation) {
          reserveStatusLimitCheck(project, products);
        }
      };

      await fetchProductsLimitCheck();
      if (reserveCheck.includes(false)) {
        setSubmitLoading(false);
        return;
      }

      const intentMetadata = {
        aid: deliveryAddress.aid,
        quantity: selectedProduct.selectedQuantityTotal,
        price: Math.floor(paymentAmount),
        postage: 0, // キャンペーン中
        projectRef: selectedProduct.projectRef,
        uid,
        zipcode,
        address,
        phoneNumber,
        name,
        orderNumber,
        projectName: selectedProduct.projectName,
        productNumber,
        reserveId,
        originalPrice: selectedProduct.retailPrice,
        discountRate,
        couponCode,
      };
      const reserveData = {
        productData,
        pid,
        customerID: customerID,
        paymentMethodID: useCredit.pmID,
        intentMetadata,
        ...intentMetadata,
      };
      await sendReserveProduct(reserveData);
    } catch (error) {
      logError(error);
      if (error instanceof Error) {
        if (error.message === stripeError.payment.authorization) {
          toast.error("購入に失敗しました、もう一度お試しください。");
          setSubmitLoading(false);
          return;
        }
        if (true) {
          const data: SlackSendData = {
            scene: "error",
            channel: "#error_notice",
            id: pid,
            description: {
              error: {
                message: `ID:${pid}を使用してStripeから予約履歴のmetaDataをFirestoreへ保存してください`,
              },
            },
          };
          slackNotice(data);
          setSubmitLoading(false);
          return;
        }
      }
    }
    analyticsCustomLogEvent("begin_checkout");
    setSubmitLoading(false);
    const shareData = {
      prevLocation,
      creatorUid: creatorInfo.value,
      image: selectedProduct.image,
    };
    push("/reservedone", shareData);
  };

  // 購入
  // const buySubmit = async () => {
  //   setSubmitLoading(true);

  //   const { uid } = user;

  //   const orderNumber = await deliveryOrderNumber();
  //   const { address, zipcode, phoneNumber, name } =
  //     await fetchDeliveryLocationData(uid, deliveryAddress.aid);

  //   const productData = {
  //     price: selectedProduct.retailPrice,
  //     // quantity: selectedProduct.purchaseQuantity,
  //     // productRef: selectedProduct.productRef,
  //     projectRef: selectedProduct.projectRef,
  //   };
  //   const shipFee = 0; //今は適当

  //   const intentMetadata = {
  //     aid: deliveryAddress.aid,
  //     postage: 500,
  //     productData: JSON.stringify([productData]),
  //     uid,
  //     zipcode,
  //     address,
  //     phoneNumber,
  //     name,
  //     orderNumber,
  //     // totalAmount: total,
  //   };
  //   const intentData = {
  //     // amount: total + shipFee,
  //     customerID: customerID,
  //     paymentMethodID: useCredit.pmID,
  //     productData: [productData],
  //     metadata: intentMetadata,
  //   };
  //   let pid;
  //   try {
  //     const paymentIntentResponse = await stripeIntentPaymentMethod(intentData);
  //     pid = paymentIntentResponse.data.id;
  //     // statusCodeがあったらエラー
  //     if (paymentIntentResponse.data.statusCode)
  //       throw Error(stripeError.payment.authorization);

  //     const buyData = {
  //       // totalAmount: total,
  //       aid: deliveryAddress.aid,
  //       pid,
  //       postage: shipFee,
  //       productData: [productData],
  //       uid,
  //       orderNumber,
  //     };
  //     const buyRes = await sendBuyProduct(buyData);
  //     // batchでcommitした数の配列が返ってくることを期待しているので、配列がなければエラー
  //     if (!Array.isArray(buyRes.data))
  //       throw Error(fireStoreBatchError.notArray);
  //   } catch (error) {
  //     logError(error);
  //     if (error instanceof Error) {
  //       if (error.message === stripeError.payment.authorization) {
  //         toast.error("購入に失敗しました、もう一度お試しください。");
  //         setSubmitLoading(false);
  //         return;
  //       }
  //       if (error.message === fireStoreBatchError.notArray) {
  //         const retrieveRes = await stripeRetrievePaymentIntent({
  //           pid,
  //         });

  //         const rebornData = retrieveRes.data;
  //         const {
  //           postage,
  //           orderNumber,
  //           productData,
  //           totalAmount,
  //           ...omitData
  //         } = rebornData;

  //         const buyData = {
  //           pid,
  //           postage: parseInt(postage),
  //           productData: JSON.parse(productData ?? ""),
  //           orderNumber: parseInt(orderNumber),
  //           totalAmount: parseInt(totalAmount ?? "0"),
  //           ...omitData,
  //         };
  //         const buyRes = await sendBuyProduct(buyData);
  //         // TODO:方向性決まったら書き換える
  //         if (!Array.isArray(buyRes.data)) {
  //           const data: SlackSendData = {
  //             scene: "error",
  //             channel: "#error_notice",
  //             id: pid,
  //             description: {
  //               error: {
  //                 message: `ID:${pid}を使用してStripeから予約履歴のmetaDataをFirestoreへ保存してください`,
  //               },
  //             },
  //           };
  //           slackNotice(data);
  //           return;
  //         }
  //       }
  //     }
  //   }
  //   analyticsCustomLogEvent("purchase");
  //   setSubmitLoading(false);
  //   push("/buydone");
  //   // pushして画面変える
  // };

  const reserveDebounce = () => {
    debounce(() => {
      reserveSubmit();
    });
  };
  return (
    <div>
      {(underReservation || inProduction || sale) && (
        <Center>
          <GrayButton
            onClick={() => reserveDebounce()}
            width={300}
            className={{
              fontSize: 12,
            }}
          >
            予約を確定する
          </GrayButton>
        </Center>
      )}
      {/* {sale && (
        <Center>
          <GrayButton
            onClick={() => handleOpen()}
            disabled={deliveryAndCreditSelectCheck()}
            width={300}
          >
            購入する
          </GrayButton>
        </Center>
      )} */}
    </div>
  );
};

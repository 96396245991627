import { useEffect, useState } from "react";
import { Backdrop, Fade, makeStyles, Modal } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js/pure";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Address } from "../../Types/userType";
import { GrayBack } from "../MyPage/GrayBack";
import { fetchAddress } from "../../features/addressOperation";
import { toast } from "react-toastify";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { DeliveryAddressForm } from "../DeliveryReg/DeliveryAddressForm";
import { CSSTransition } from "react-transition-group";
import { NormalModal } from "../feature/Modal/NormalModal";
import { SalesProject, SelectedProduct } from "../../Types/productType";
import { PreOrderProduct } from "./PreOrderProduct";
import { PreOrderTitle } from "./PreOrderTitle";
import {
  fetchCreditsWithPreOrder,
  fetchCustomerId,
} from "../../features/creditOperation";
import { Credit } from "../../Types/paymentType";
import { getStripePublicKey } from "../../function/common";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { CreditForm } from "../Payment/CreditForm";
import { CreditCard } from "../Payment/CreditCard";
import { SelectedAddress } from "./SelectedAddress";
import { AddressList } from "./AddressList";
import { CreditList } from "./CreditList";
import { LoadingSkelton } from "../atoms/Skelton/LoadingSkelton";

const stripePromise = loadStripe(getStripePublicKey());

export const ReservationDeliveryPaymentSetup = (props: RouteComponentProps) => {
  const { history } = props;
  const { projectData, selectedProduct } = history.location.state as {
    projectData: SalesProject;
    selectedProduct: SelectedProduct;
  };

  const { push } = useHistory();
  const [addresses, setAddresses] = useState<Omit<Address, "uid">[]>([]);
  const [address, setAddress] = useState<Omit<Address, "uid">>();
  // アドレス作成用のbool値を管理、trueになるとフォームが開く
  const [isAddressCreationMode, setIsAddressCreationMode] = useState(false);
  const [isCloseAddressForm, setIsCloseAddressForm] = useState(false);
  const [isSelectAddresses, setIsSelectAddresses] = useState(false);
  // クレジット作成用のbool値を管理、trueになるとフォームが開く
  const [isCreditCreationMode, setIsCreditCreationMode] = useState(false);
  const [isCloseCreditForm, setIsCloseCreditForm] = useState(false);
  const [isSelectCredits, setIsSelectCredits] = useState(false);
  // 子コンポーネントのフォームで正常に登録できた後にuseEffect機能させるためにカウントアップしている
  const [createAddressCounter, setCreateAddressCounter] = useState(0);
  const [createCreditCounter, setCreateCreditCounter] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const [credits, setCredits] = useState<Credit[]>([]);
  const [credit, setCredit] = useState<Credit>();

  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [isCreditLoading, setIsCreditLoading] = useState(true);

  const user = useSelector(selectUser);

  const useStyles = makeStyles({
    container: {
      padding: "8px",
    },
    addressesContainer: {
      marginTop: 8,
    },
    addressInfoArea: {
      padding: 10,
    },
    addressTitle: {
      height: 20,
      "& p": {
        fontSize: 14,
      },
    },
    addressArea: {
      display: "flex",
    },
    changeArea: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    addressCheckArea: {
      flex: 1,
    },
    addressInfo: {
      flex: 7,
    },
    radio: {
      margin: "25px auto 0",
      display: "block",
    },
    formWrapper: {
      overflow: "hidden",
    },
    formContent: {
      transition: "max-height 0.5s ease-out, opacity 0.5s ease-out",
      "&.form-enter, &.form-exit-done": {
        maxHeight: 0,
        opacity: 0,
      },
      "&.form-enter-active, &.form-enter-done": {
        maxHeight: "1000px", // Adjust this value based on your form's height
        opacity: 1,
      },
      "&.form-exit": {
        maxHeight: "1000px",
        opacity: 1,
      },
      "&.form-exit-active": {
        maxHeight: 0,
        opacity: 0,
      },
    },
    formCloseArea: {
      width: "100%",
      margin: "16px auto 0",
    },
    creditsContainer: {
      marginTop: 8,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    selectAddressArea: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      padding: 16,
    },
  });
  const classes = useStyles();

  const fetchAddressData = async () => {
    try {
      await fetchAddress(
        setAddresses,
        setAddress,
        setIsAddressCreationMode,
        setIsAddressLoading
      );
    } catch (error) {
      toast.error("住所の取得に失敗しました。");
    }
  };

  const fetchCustomerID = async () => {
    try {
      await fetchCustomerId(
        setCustomerId,
        setIsCreditLoading,
        setIsCreditCreationMode
      );
    } catch (error) {
      toast.error("クレジットカードの取得に失敗しました。");
    }
  };

  const fetchCredits = async (cusId: string) => {
    try {
      await fetchCreditsWithPreOrder(
        cusId,
        setCredits,
        setCredit,
        setIsCreditCreationMode
      );
      setIsCreditLoading(false);
    } catch (error) {
      toast.error("クレジットカードの取得に失敗しました。");
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, [createAddressCounter]);

  useEffect(() => {
    fetchCustomerID();
  }, []);

  useEffect(() => {
    if (customerId !== "") fetchCredits(customerId);
  }, [customerId, createCreditCounter]);

  return (
    <div className={classes.container}>
      <BrowserBackHeader label="お届け先選択" browserBack={history} />
      <PreOrderProduct selectedProduct={selectedProduct} />
      <Center
        className={{
          marginTop: 8,
        }}
      >
        <GrayButton
          disabled={!credit || !address}
          className={{
            fontSize: 12,
          }}
          width={250}
          onClick={() =>
            push("/product/reserve", {
              projectData: projectData,
              selectedProduct: selectedProduct,
              address: address,
              credit: credit,
              customerId: customerId,
            })
          }
        >
          最終確認に進む
        </GrayButton>
      </Center>
      {address && (
        <div className={classes.addressesContainer}>
          <GrayBack isDark height={1} />
          <PreOrderTitle title="お届け先" />
          <GrayBack isDark height={1} />
          <SelectedAddress
            address={address}
            addresses={addresses}
            setIsSelectAddresses={setIsSelectAddresses}
          />
        </div>
      )}
      {isAddressLoading && (
        <div className={classes.addressesContainer}>
          <GrayBack isDark height={1} />
          <PreOrderTitle title="お届け先" />
          <GrayBack isDark height={1} />
          <LoadingSkelton height={92} />
          <GrayBack isDark height={1} />
        </div>
      )}
      {!isAddressCreationMode && (
        <Center
          className={{
            gap: 8,
            marginTop: 8,
          }}
        >
          <GrayButton
            width={250}
            onClick={() => {
              setIsAddressCreationMode(true);
            }}
            className={{
              fontSize: 12,
            }}
          >
            + 新しい住所の登録をする
          </GrayButton>
        </Center>
      )}

      <div
        className={classes.formWrapper}
        style={{
          marginTop: isAddressCreationMode ? 8 : 0,
        }}
      >
        <CSSTransition
          in={isAddressCreationMode}
          timeout={300}
          classNames="form"
          unmountOnExit
        >
          <div className={classes.formContent}>
            <GrayBack isDark height={1} />
            <PreOrderTitle title="下記フォームにお届け先情報を入力してください" />
            <GrayBack isDark height={1} />
            <DeliveryAddressForm setCreateCounter={setCreateAddressCounter} />
          </div>
        </CSSTransition>
        {isAddressCreationMode && addresses.length > 0 && (
          <Center className={{ margin: "16px 0" }}>
            <GrayButton
              width={250}
              onClick={() => {
                setIsCloseAddressForm(true);
              }}
              backgroundColor="rgba(0,0,0,0)"
              color="rgb(80, 80, 80)"
              className={{
                fontSize: 12,
              }}
              variant="outlined"
            >
              登録フォームを閉じる
            </GrayButton>
          </Center>
        )}
      </div>
      {credit && (
        <div className={classes.creditsContainer}>
          <GrayBack isDark height={1} />
          <PreOrderTitle title="お支払いするクレジットカード情報" />
          <GrayBack isDark height={1} />
          <CreditCard
            credit={credit}
            credits={credits}
            setIsSelectCredits={setIsSelectCredits}
          />
        </div>
      )}
      {isCreditLoading && (
        <div className={classes.creditsContainer}>
          <GrayBack isDark height={1} />
          <PreOrderTitle title="お支払いするクレジットカード情報" />
          <GrayBack isDark height={1} />
          <LoadingSkelton height={86} />
          <GrayBack isDark height={1} />
        </div>
      )}
      {!isCreditCreationMode && (
        <Center
          className={{
            gap: 8,
            marginTop: 8,
          }}
        >
          <GrayButton
            width={250}
            onClick={() => {
              setIsCreditCreationMode(true);
            }}
            className={{
              fontSize: 12,
            }}
          >
            + 新しいクレジットカードの登録をする
          </GrayButton>
        </Center>
      )}
      <div
        className={classes.formWrapper}
        style={{
          marginTop: isCreditCreationMode ? 8 : 0,
        }}
      >
        <CSSTransition
          in={isCreditCreationMode}
          timeout={300}
          classNames="form"
          unmountOnExit
        >
          <div className={classes.formContent}>
            <GrayBack isDark height={1} />
            <PreOrderTitle title="下記フォームにクレジットカード情報を入力してください" />
            <GrayBack isDark height={1} />
            <Elements stripe={stripePromise}>
              <CreditForm
                uid={user.uid}
                customerId={customerId}
                setCustomerId={setCustomerId}
                setCreateCounter={setCreateCreditCounter}
              />
            </Elements>
          </div>
        </CSSTransition>
        {isCreditCreationMode && credits.length > 0 && (
          <Center className={{ margin: "16px 0" }}>
            <GrayButton
              width={250}
              onClick={() => {
                setIsCloseCreditForm(true);
              }}
              backgroundColor="rgba(0,0,0,0)"
              color="rgb(80, 80, 80)"
              className={{
                fontSize: 12,
              }}
              variant="outlined"
            >
              登録フォームを閉じる
            </GrayButton>
          </Center>
        )}
      </div>
      <NormalModal
        isShow={isCloseAddressForm}
        setState={setIsCloseAddressForm}
        leftButtonText="入力を続ける"
        leftOnClick={() => setIsCloseAddressForm(false)}
        rightButtonText="フォームを閉じる"
        rightOnClick={() => setIsAddressCreationMode(false)}
        description={`住所の登録フォームを閉じますか？\n閉じた場合入力は初期化されます。`}
      />
      <NormalModal
        isShow={isCloseCreditForm}
        setState={setIsCloseCreditForm}
        leftButtonText="入力を続ける"
        leftOnClick={() => setIsCloseCreditForm(false)}
        rightButtonText="フォームを閉じる"
        rightOnClick={() => setIsCreditCreationMode(false)}
        description={`クレジットの登録フォームを閉じますか？\n閉じた場合入力は初期化されます。`}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isSelectAddresses}
        onClose={() => setIsSelectAddresses(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isSelectAddresses}>
          <div className={classes.selectAddressArea}>
            <GrayBack isDark height={1} />
            <PreOrderTitle title="お届け先を選択してください" />
            <GrayBack isDark height={1} />
            <AddressList
              addresses={addresses}
              selectedAddress={address}
              setAddress={setAddress}
              setIsSelectAddresses={setIsSelectAddresses}
            />
            <Center>
              <GrayButton onClick={() => setIsSelectAddresses(false)}>
                閉じる
              </GrayButton>
            </Center>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isSelectCredits}
        onClose={() => setIsSelectCredits(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isSelectCredits}>
          <div className={classes.selectAddressArea}>
            <GrayBack isDark height={1} />
            <PreOrderTitle title="お支払いに使用するカードを選択してください" />
            <GrayBack isDark height={1} />
            <CreditList
              credits={credits}
              selectedCredit={credit}
              setCredit={setCredit}
              setIsSelectCredits={setIsSelectCredits}
            />
            <Center>
              <GrayButton onClick={() => setIsSelectCredits(false)}>
                閉じる
              </GrayButton>
            </Center>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

import { makeStyles } from "@material-ui/core";
import { Credit } from "../../Types/paymentType";
import { Text } from "../atoms/Text/Text";
import Visa from "./Visa";
import MasterCard from "./MasterCard";
import Discover from "./Discover";
import JCB from "./JCB";
import AmericanExpress from "./AmericanExpress";
import DinersClub from "./DinersClub";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { GrayBack } from "../MyPage/GrayBack";

type Props = {
  credit?: Credit;
  credits?: Credit[];
  isSelectMode?: boolean;
  setCredit?: React.Dispatch<React.SetStateAction<Credit | undefined>>;
  setIsSelectCredits?: React.Dispatch<React.SetStateAction<boolean>>;
  isCurrent?: boolean;
};

export const CreditCard = (props: Props) => {
  const {
    credit,
    credits,
    isSelectMode,
    setCredit,
    setIsSelectCredits,
    isCurrent,
  } = props;
  const useStyles = makeStyles({
    cardContainer: {},
    creditInfoArea: {
      display: "flex",
      flexDirection: "row",
      height: 66,
      gap: 16,
      padding: 10,
    },
    cardImage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
    },
    cardInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    changeAreaEnd: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginLeft: "auto",
    },
    changeArea: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  return (
    <div key={credit?.pmID} className={classes.cardContainer}>
      {isCurrent && (
        <Text
          isDark
          text="※現在選択されているカード"
          className={{
            marginLeft: 20,
            paddingTop: 8,
          }}
          align="center"
        />
      )}
      <div className={classes.creditInfoArea}>
        <div className={classes.cardImage}>
          {credit?.brand === "visa" && <Visa />}
          {credit?.brand === "mastercard" && <MasterCard />}
          {credit?.brand === "discover" && <Discover />}
          {credit?.brand === "jcb" && <JCB />}
          {credit?.brand === "amex" && <AmericanExpress />}
          {credit?.brand === "diners" && <DinersClub />}
        </div>
        <div className={classes.cardInfo}>
          <Text text={`カード番号: ****-${credit?.last4}`} isDark />
          <Text
            text={`有効期限: ${credit?.expYear}/${credit?.expMonth}`}
            isDark
          />
        </div>
        <div className={classes.changeAreaEnd}>
          {credits && credits.length > 1 && (
            <div className={classes.changeArea}>
              <GrayButton
                width={90}
                backgroundColor="rgba(0,0,0,0)"
                color="rgb(80, 80, 80)"
                variant="outlined"
                className={{
                  fontSize: 10,
                }}
                onClick={() => {
                  setIsSelectCredits && setIsSelectCredits(true);
                }}
              >
                クレジットを変更する
              </GrayButton>
            </div>
          )}
          {isSelectMode && (
            <div className={classes.changeArea}>
              <GrayButton
                width={100}
                backgroundColor="white"
                color="rgb(80, 80, 80)"
                variant="outlined"
                className={{
                  fontSize: 10,
                }}
                onClick={() => {
                  setCredit && setCredit(credit);
                  setIsSelectCredits && setIsSelectCredits(false);
                }}
              >
                このカードで支払う
              </GrayButton>
            </div>
          )}
        </div>
      </div>
      <GrayBack height={1} isDark />
    </div>
  );
};

import React from "react";

import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

type Props = {
  className?: CSSProperties;
};

const GrayBorder = (props: Props) => {
  const { className } = props;
  const useStyles = makeStyles({
    border: {
      borderBottom: "1px solid rgb(112,112,112)",
      ...className,
    },
  });
  const classes = useStyles();
  return <div className={classes.border} />;
};

export default React.memo(GrayBorder);
